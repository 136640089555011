import { StyledTableCell, StyledTableRow } from "../StyledTableComps";
import { IconButton, Box, Paper, TableBody, TableContainer, Table, TextField, TableHead, TableRow, Grid, Typography, TableCell, tableCellClasses, Tooltip, Stack } from "@mui/material";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { useEffect, useState } from "react";
import AddIcon from '@mui/icons-material/Add';
import { TemplateToolPanel } from "../ToolPanels/TemplateToolPanel";
import CloseIcon from '@mui/icons-material/Close';

export default function TemplateTable({ title, tooltipText, templates, handleRowClick, rowTooltip, tableId = '', panelParams, }) {
    const [openAddNew, setOpenAddNew] = useState(false);

    function handleClickAdd() {
        setOpenAddNew(true);
    }

    function generateRowTooltip(template) {
        if (template?.tooltipText) {
            return <span>{template.tooltipText}</span>
        } else {
            return rowTooltip ? rowTooltip(template.data) : '';
        }
    }

    return (
        <Table
            sx={{ width: '100%', }}
            // sx={{ width:'100px' }} 
            size="small"
            aria-label="customized table"
        >
            <Dialog open={openAddNew} maxWidth='sm'>
                <DialogTitle>
                    <Stack direction={'row'}>
                        <Typography>Add a template</Typography>
                        <Box sx={{ flexGrow: 1, }} />
                        <IconButton onClick={() => setOpenAddNew(false)} size={'small'}>
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <TemplateToolPanel
                        {...panelParams}
                        addTemplate={(template) => {
                            setOpenAddNew(false);
                            panelParams.addTemplate(template);
                        }}
                    />
                </DialogContent>
            </Dialog>
            <TableHead>
                <StyledTableRow>
                    <Tooltip title={tooltipText} arrow placement="left">
                        <StyledTableCell sx={{ p: '4px', fontWeight: 'light' }} component="th" scope="row">{title}</StyledTableCell>
                    </Tooltip>
                    <Box sx={{ flexGrow: 1, }} />
                    {panelParams && <IconButton onClick={handleClickAdd} size='small'>
                        <AddIcon />
                    </IconButton>}
                </StyledTableRow>
            </TableHead>
            <TableBody >
                {templates.map((template) =>
                    <div id={`drop-zone-${tableId}${template.id}`}>
                        <Tooltip title={generateRowTooltip(template)} arrow placement="left">
                            <StyledTableRow hover sx={{ border: '2px', display: 'flex', flexGrow: 1 }} >
                                <StyledTableCell
                                    sx={{
                                        p: '4px',
                                        fontWeight: 'light',
                                        verticalAlign: 'middle',
                                        width: '100%',
                                    }}
                                    onClick={() => handleRowClick(template)}
                                    fontSize={12}
                                    component="th"
                                    scope="row">
                                    {template.name}<ArrowRightIcon sx={{ verticalAlign: 'middle', }} size="small" />
                                </StyledTableCell>
                            </StyledTableRow>
                        </Tooltip>
                    </div>
                )}
            </TableBody>
        </Table>
    );
  }
