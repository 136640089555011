import { useEffect, useRef, useCallback, } from "react";

const defaultDelay = 12 * 60 * 60 * 1000; // 12 hours

export const useInactivityLogout = (logoutCallback, delay = defaultDelay) => {
    const timeoutIdRef = useRef(null);

    const resetTimer = useCallback(() => {
        if (timeoutIdRef.current) {
            clearTimeout(timeoutIdRef.current);
        }

        timeoutIdRef.current = setTimeout(() => {
            logoutCallback();
        }, delay);
    }, [logoutCallback, delay]);

    useEffect(() => {
        //List of events that will reset the inactivity timer
        const events = ["mousemove", "keydown", "wheel", "DOMMouseScroll", "mousewheel", "mousedown", "touchstart", "touchmove", "click"];

        events.forEach((event) => {
            window.addEventListener(event, resetTimer);
        });

        resetTimer(); //Start the timer when the hook is mounted

        //Cleanup: remove event listeners & clear timeout
        return () => {
            events.forEach((event) => {
                window.removeEventListener(event, resetTimer);
            });

            if (timeoutIdRef.current) {
                clearTimeout(timeoutIdRef.current);
            }
        };
    }, [resetTimer]);
}
