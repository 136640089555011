import { yupResolver } from '@hookform/resolvers/yup';
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import GetAppIcon from '@mui/icons-material/GetApp';
import { Autocomplete, Box, Button, Collapse, Divider, FormControlLabel, IconButton, TextField, Toolbar, Tooltip } from "@mui/material";
import Grid from "@mui/material/Grid2";
import dayjs from 'dayjs';
import tz from 'dayjs/plugin/timezone'; // dependent on utc plugin
import utc from 'dayjs/plugin/utc';
import { useMemo, useState } from "react";
import { useForm } from 'react-hook-form';
import { array, date, number, object, string } from "yup";
import { renderStandardTimezones } from "../../utils/renderTimezones";
import useFilteredCriteria from "../CriteriaContext/useFilteredCriteria";
import FormAutocomplete from "../FormControls/FormAutocomplete";
import FormCheckbox from "../FormControls/FormCheckbox";
import FormDatePicker from "../FormControls/FormDateTimePicker";
import momentTimezones from "../momentTimezones";

const CriteriaPanel = (props) => {
    const { isProUser, handleFetch, disableFetch, open, handleToggleOpen, } = props;
    const { pors, pods, providers, filteredTSCombos, } = useFilteredCriteria({});
    const formatString = 'MM/DD/YYYY HH:mm';
    const criteriaStorageKey = 'fast-path-criteria';

    dayjs.extend(utc);
    dayjs.extend(tz);

    const schema = object().shape({
        timezone: string().required('Timezone is required'),
        startDate: date().required('Start Date is required').when('timezone', (timezone, schema) => {
            return timezone && schema.min(dayjs().tz(momentTimezones[timezone]).startOf('day'), 'Start Date cannot be before today.');
        }),
        stopDate: date().required('Start Date is required').when('timezone', (timezone, schema) => {
            return timezone && schema.min(dayjs().tz(momentTimezones[timezone]).startOf('day'), 'Stop Date cannot be before today.');
        }).when('startDate', (startDate, schema) => {
            return startDate && schema.min(startDate, 'Stop Date cannot be before Start Date.');
        }),
        por: array().required('POR is required').min(1, 'POR is required'),
        pod: array().required('POD is required').min(1, 'POD is required'),
        maxLegs: string().required('Max Legs is required'),
        tsClass: array().min(1, 'TS Class is required'),
        tsIncrement: array().min(1, 'TS Increment is required'),
        tsType: array().min(1, 'TS Type is required'),
        tsPeriod: array().min(1, 'TS Period is required'),
        tsWindow: array().min(1, 'TS Window is required'),
        minCapacity: number().typeError('Value must be an integer.').required('Min Capacity is required').min(0, 'Min Capacity must be at least 0'),
    })

    const storedValues = useMemo(() => {
        return JSON.parse(localStorage.getItem(criteriaStorageKey) ?? '{}');
    }, []);

    const defaultValues = useMemo(() => ({
        maxLegs: '4',
        por: ['CSWS'],
        pod: ['AECI'],
        hardLimit: false,
        tsClass: ['NON-FIRM'],
        tsIncrement: ['HOURLY'],
        tsType: ['POINT_TO_POINT'],
        tsPeriod: ['FULL_PERIOD'],
        tsWindow: ['FIXED'],
        minCapacity: 100,
        ...storedValues,
        timezone: storedValues.timezone ? storedValues.timezone.split(' ')[0] : 'Pacific', //If user has e.g. Mountain Standard Time in local storage, we need to convert it to Mountain
        startDate: dayjs().startOf('hour').add(1, 'hour'),
        stopDate: dayjs().startOf('hour').add(2, 'hour'),
        tsSubclass: '',
    }), []);

    const { register, handleSubmit, control, formState: { errors, }, watch, setValue, resetField } = useForm({
        defaultValues: defaultValues,
        resolver: yupResolver(schema),
        mode: 'onBlur',
    });

    const maxLegs = watch('maxLegs');
    const minCapacity = watch('minCapacity');
    const timezone = watch('timezone');
    const por = watch('por');
    const pod = watch('pod');

    function handleWildcardUpdate(key, value) {
        const [lastAdded] = value?.slice(-1);
        if (lastAdded === '*') {
            setValue(key, ['*'])
        } else {
            setValue(key, value.filter(option => option !== '*'))
        }
    }

    const numMaxLegs = isProUser ? 10 : 4;
    const maxLegOptions = [...Array(numMaxLegs).keys()].map(i => `${i + 1}`)

    //if user is not pro, they can only have one por/pod at a time
    let porVal;
    let podVal;
    if (isProUser) {
        porVal = por ?? [];
        podVal = pod ?? [];
    } else {
        porVal = por ? por[0] : '';
        podVal = pod ? pod[0] : '';
    }

    function onSubmit(data) {
        const { startDate, stopDate, ...rest } = data;
        localStorage.setItem(criteriaStorageKey, JSON.stringify(rest));
        handleFetch({
            startDate: dayjs(startDate).tz(momentTimezones[rest.timezone]).format(formatString),
            stopDate: dayjs(stopDate).tz(momentTimezones[rest.timezone]).format(formatString),
            ...rest,
            timezone: rest.timezone.includes('Standard') ? rest.timezone : renderStandardTimezones[rest.timezone],
        });
    }

    let maxLegHelperText = '';
    if (errors.maxLegs) {
        maxLegHelperText = errors.maxLegs.message;
    } else if (maxLegs > 3) {
        maxLegHelperText = 'Load times may be slower when including large numbers of legs.'
    }

    let minCapacityHelperText = '';
    if (errors.minCapacity) {
        minCapacityHelperText = errors.minCapacity.message;
    } else if (minCapacity < 50) {
        minCapacityHelperText = 'Load times may be slower with a low min capacity.'
    }

    return (
        <div className="thin-scroll">
            <Box sx={{ display: 'flex', p: 1 }}>
                <Grid container direction={'column'}>
                    <Grid size={12}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
							paddingRight: '8px',
                        }}
                    >{open && (<><h3>Fetch Criteria</h3><Box sx={{ flexGrow: 1, }} /></>)}
                        <IconButton onClick={handleToggleOpen} size="large" sx={{ borderRadius: '5px' }}>
                            {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                        </IconButton>
                    </Grid>
                    <Divider />
                    <Collapse in={open}>
                        <form id='fast-path-criteria-form' onSubmit={handleSubmit(onSubmit)}>
                            <Grid container justifyContent="center" style={{ marginTop: '20px' }} spacing={2}>
                                <Grid size={6}>
                                    <FormAutocomplete
                                        fullWidth
                                        options={['Pacific', 'Eastern', 'Central', 'Mountain']}
                                        control={control}
                                        name='timezone'
                                        disableClearable
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                label="Prevailing Timezone"
                                                size='small'
                                                color="success"
                                                error={!!errors.timezone}
                                                helperText={errors.timezone?.message}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid size={6}>
                                    <Tooltip title="The maximum number of legs in a path." arrow placement="top">
                                        <FormAutocomplete
                                            name='maxLegs'
                                            control={control}
                                            fullWidth
                                            options={maxLegOptions}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    size="small"
                                                    sx={{ '& p': { color: 'orangered', } }}
                                                    variant="outlined"
                                                    label="Max Legs"
                                                    color="success"
                                                    error={!!errors.maxLegs}
                                                    helperText={maxLegHelperText}
                                                />
                                            )}
                                        />
                                    </Tooltip>
                                </Grid>
                                <Grid size={6}>
                                    <FormDatePicker
                                        name='startDate'
                                        control={control}
                                        disablePast
                                        label="Start Date"
                                        format="MM/DD/YYYY HH:mm"
                                        views={['year', 'month', 'day', 'hours']}
                                        timezone={momentTimezones[timezone]}
                                        slotProps={{
                                            textField: {
                                                size: 'small',
                                                error: !!errors.startDate,
                                                helperText: errors.startDate?.message,
                                                fullWidth: true,
                                                onBlur: () => {
                                                    const startOfHour = dayjs(watch('startDate')).startOf('hour');
                                                    setValue('startDate', startOfHour);
                                                },
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid size={6}>
                                    <FormDatePicker
                                        name='stopDate'
                                        control={control}
                                        disablePast
                                        label="Stop Date"
                                        format="MM/DD/YYYY HH:mm"
                                        views={['year', 'month', 'day', 'hours']}
                                        timezone={momentTimezones[timezone]}
                                        slotProps={{
                                            textField: {
                                                size: 'small',
                                                error: !!errors.stopDate,
                                                helperText: errors.stopDate?.message,
                                                fullWidth: true,
                                                onBlur: () => {
                                                    const startOfHour = dayjs(watch('stopDate')).startOf('hour');
                                                    setValue('stopDate', startOfHour);
                                                },
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid size={6}>
                                    <Tooltip title="Choose a Point of Receipt." arrow placement="top">
                                        <Autocomplete
                                            value={porVal}
                                            onChange={(_, newVal) => {
                                                isProUser ? setValue('por', newVal) : setValue('por', [newVal]);
                                            }}
                                            fullWidth
                                            autoComplete
                                            autoSelect
                                            autoHighlight
                                            multiple={isProUser}
                                            options={pors ?? []}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    size="small"
                                                    variant="outlined"
                                                    label="POR"
                                                    color="success"
                                                    error={!!errors.por}
                                                    helperText={errors.por?.message}
                                                />
                                            )}
                                        />
                                    </Tooltip>
                                </Grid>
                                <Grid size={6}>
                                    <Tooltip title="Choose a Point of Delivery." arrow placement="top">
                                        <Autocomplete
                                            value={podVal}
                                            onChange={(_, newVal) => {
                                                isProUser ? setValue('pod', newVal) : setValue('pod', [newVal]);
                                            }}
                                            fullWidth
                                            autoHighlight
                                            autoComplete
                                            autoSelect
                                            multiple={isProUser}
                                            options={pods ?? []}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    size="small"
                                                    variant="outlined"
                                                    label="POD"
                                                    color="success"
                                                    error={!!errors.pod}
                                                    helperText={errors.pod?.message}
                                                />
                                            )}
                                        />
                                    </Tooltip>
                                </Grid>
                                <Grid size={12}>
                                    <Tooltip title="Select * to include any TS Class" arrow placement="top">
                                        <FormAutocomplete
                                            name='tsClass'
                                            control={control}
                                            onChange={(_, newVal) => {
                                                handleWildcardUpdate('tsClass', newVal);
                                            }}
                                            fullWidth
                                            multiple
                                            options={['*', ...filteredTSCombos.tsClasses]}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    size="small"
                                                    variant="outlined"
                                                    label="TS Class"
                                                    color="success"
                                                    error={!!errors.tsClass}
                                                    helperText={errors.tsClass?.message}
                                                />
                                            )}
                                        />
                                    </Tooltip>
                                </Grid>
                                <Grid size={12}>
                                    <Tooltip title="Select * to include any TS Increment" arrow placement="top">
                                        <FormAutocomplete
                                            name='tsIncrement'
                                            control={control}
                                            onChange={(_, newVal) => {
                                                handleWildcardUpdate('tsIncrement', newVal);
                                            }}
                                            fullWidth
                                            multiple
                                            options={['*', ...filteredTSCombos.serviceIncrements]}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    size="small"
                                                    variant="outlined"
                                                    label="TS Increment"
                                                    color="success"
                                                    error={!!errors.tsIncrement}
                                                    helperText={errors.tsIncrement?.message}
                                                />
                                            )}
                                        />
                                    </Tooltip>
                                </Grid>
                                <Grid size={12}>
                                    <Tooltip title="Select * to include any TS Type" arrow placement="top">
                                        <FormAutocomplete
                                            name={'tsType'}
                                            control={control}
                                            onChange={(_, newVal) => {
                                                handleWildcardUpdate('tsType', newVal);
                                            }}
                                            fullWidth
                                            multiple
                                            options={['*', ...filteredTSCombos.tsTypes]}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    size="small"
                                                    variant="outlined"
                                                    label="TS Type"
                                                    color="success"
                                                    error={!!errors.tsType}
                                                    helperText={errors.tsType?.message}
                                                />
                                            )}
                                        />
                                    </Tooltip>
                                </Grid>
                                <Grid size={12}>
                                    <Tooltip title="Select * to include any TS Period" arrow placement="top">
                                        <FormAutocomplete
                                            name={'tsPeriod'}
                                            control={control}
                                            onChange={(_, newVal) => {
                                                handleWildcardUpdate('tsPeriod', newVal);
                                            }}
                                            fullWidth
                                            multiple
                                            options={['*', ...filteredTSCombos.tsPeriods]}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    size="small"
                                                    variant="outlined"
                                                    label="TS Period"
                                                    color="success"
                                                    error={!!errors.tsPeriod}
                                                    helperText={errors.tsPeriod?.message}
                                                />
                                            )}
                                        />
                                    </Tooltip>
                                </Grid>
                                <Grid size={12}>
                                    <Tooltip title="Select * to include any TS Window" arrow placement="top">
                                        <FormAutocomplete
                                            name={'tsWindow'}
                                            control={control}
                                            onChange={(_, newVal) => {
                                                handleWildcardUpdate('tsWindow', newVal);
                                            }}
                                            fullWidth
                                            multiple
                                            options={['*', ...filteredTSCombos.tsWindows]}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    size="small"
                                                    variant="outlined"
                                                    label="TS Window"
                                                    color="success"
                                                    error={!!errors.tsWindow}
                                                    helperText={errors.tsWindow?.message}
                                                />
                                            )}
                                        />
                                    </Tooltip>
                                </Grid>
                                {/*<Grid  size={12}>
                        <Tooltip title="Select * to include any TS Subclass" arrow placement="top">
                          <FormAutocomplete
                            name={'tsSubclass'}
                            control={control}
                            onChange={(_, newVal) => {
                              handleWildcardUpdate('tsSubclass', newVal);
                            }}
                            fullWidth
                            multiple
                            options={['*', ...filteredTSCombos.tsSubclasses]}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size="small"
                                variant="outlined"
                                label="TS Subclass"
                                color="success" 
                                error={!!errors.tsSubclass}
                                helperText={errors.tsSubclass?.message}
                              />
                            )}
                          />
                        </Tooltip>
                            </Grid>*/}
                                <Grid size={12}>
                                    <Tooltip title="Selected providers will not appear in results." arrow placement="top">
                                        <FormAutocomplete
                                            name={'excludeTp'}
                                            control={control}
                                            fullWidth
                                            multiple
                                            options={providers ?? []}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    size='small'
                                                    label="Excluded Providers"
                                                    color="success"
                                                    error={!!errors.excludeTp}
                                                    helperText={errors.excludeTp?.message}
                                                />
                                            )}
                                        />
                                    </Tooltip>
                                </Grid>
                                <Grid size={12}>
                                    <Tooltip title="Selected points will not appear in results." arrow placement="top">
                                        <FormAutocomplete
                                            name={'excludePoints'}
                                            control={control}
                                            fullWidth
                                            multiple
                                            options={pors ?? []}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    size='small'
                                                    label="Excluded Points"
                                                    color="success"
                                                    error={!!errors.excludePoints}
                                                    helperText={errors.excludePoints?.message}
                                                />
                                            )}
                                        />
                                    </Tooltip>
                                </Grid>
                                <Grid size={6}>
                                    <Tooltip title="The minimum available MW across all legs for the first hour of the specified range." arrow placement="top">
                                        <TextField
                                            {...register('minCapacity')}
                                            sx={{ '& p': { color: 'orangered', } }}
                                            variant="outlined"
                                            size='small'
                                            fullWidth
                                            label="Min Capacity"
                                            color="success"
                                            error={!!errors.minCapacity}
                                            helperText={minCapacityHelperText}
                                        />
                                    </Tooltip>
                                </Grid>
                                <Grid size={6}>
                                    <Tooltip title='If selected, results will only contain trans with the minimum capacity available across the entire profile.' arrow placement='top'>
                                        <FormControlLabel
                                            control={
                                                <FormCheckbox name={'hardLimit'} control={control} />
                                            }
                                            label="Hard Limit"
                                        />
                                    </Tooltip>
                                </Grid>
                                <Grid size={6}>
                                    <Button
                                        endIcon={<GetAppIcon />}
                                        variant="contained"
                                        type='submit'
                                        form='fast-path-criteria-form'
                                        fullWidth
                                        disabled={disableFetch}
                                    >Fetch Routes</Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Collapse>
                </Grid>
            </Box>
        </div>
    )
}

export default CriteriaPanel;
