import { IconButton, Tooltip, Typography, Card, CardContent, } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import styled from '@emotion/styled';
import { useCallback, useRef, useState, } from "react";
import AttachFileIcon from '@mui/icons-material/AttachFile';

export const useFileUploader = () => {
    const inputRef = useRef(null);
    const [files, setFiles] = useState([]);
    const [fileUrls, setFileUrls] = useState([]);

    async function handleUpdateFiles(e) {
        const filePromises = [...e.target.files].map(file => readFile(file));
        return Promise.all(filePromises).then(newFiles => {
            const dataURLs = [];
            const files = [];
            newFiles.forEach(newFileData => {
                dataURLs.push(newFileData.dataURL);
                files.push(newFileData.file);
            });

            setFileUrls(dataURLs);
            setFiles(files);
            return files;
        });
    }

    function handleFileUpload() {
        inputRef.current?.click();
    }

    function removeFile(index) {
        setFiles(files => files.filter((_, i) => i !== index));
        setFileUrls(fileUrls => fileUrls.filter((_, i) => i !== index));
    }

    // Read a file and return a promise that when resolved gives the file itself and the data URL
    function readFile(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            // Read the file via FileReader API and save result in state.
            reader.onload = function (e) {
                // Add the file name to the data URL
                let dataURL = e.target.result;
                dataURL = dataURL.replace(";base64", `;name=${file.name};base64`);
                resolve({ file, dataURL });
            };

            reader.readAsDataURL(file);
        });
    }

    const clearFiles = useCallback(() => {
        setFiles([]);
        setFileUrls([]);
    }, []);

    const FileUploader = (propsOverrides) => {
        return (
            <input
                type="file"
                ref={input => inputRef.current = input}
                name='fileUploaderInput'
                multiple
                onChange={handleUpdateFiles}
                hidden
                {...propsOverrides}
            />
        )
    }

    const FileUploadButton = () => {
        return (
            <Tooltip title="Upload File" placement="top" arrow>
                <IconButton onClick={handleFileUpload} color='primary' size='large'>
                    <AttachFileIcon fontSize='inherit' />
                </IconButton>
            </Tooltip>
        )
    }

    const PreviewContainer = styled(Card)(({ theme }) => ({
        position: 'relative',
        margin: theme.spacing(2),
        overflow: 'visible',
    }));

    const DeleteFileButton = styled(IconButton)(({ theme }) => ({
        position: 'absolute',
        top: '-9px',
        right: '-9px',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    }));

    const FilePreview = () =>
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', width: '100%', }}>
            <div style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexWrap: "wrap",
                width: "100%"
            }}>
                {files.map((file, index) => (
                    <PreviewContainer key={index}>
                        <DeleteFileButton onClick={() => removeFile(index)} size='small'>
                            <CloseIcon />
                        </DeleteFileButton>
                        <CardContent>
                            <Typography variant="body1">Name: {file.name}</Typography>
                            <Typography variant="body1">Size: {file.size} bytes</Typography>
                            <Typography variant="body1">Type: {file.type}</Typography>
                        </CardContent>
                    </PreviewContainer>
                ))}
            </div>
        </div>

    return {
        FileUploader,
        inputRef,
        files,
        handleFileUpload,
        handleUpdateFiles,
        removeFile,
        fileUrls,
        clearFiles,
        FileUploadButton,
        FilePreview,
    }
}
