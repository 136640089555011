import { Ribbon } from "../../Ribbon";
import { GridActionsGroup } from "./GridActionsGroup";
import { ViewForm } from "./ViewForm";
import { ActionsGroup } from "./ActionsGroup";

export const ReviewRibbon = ({ gridRef, toggleMWColumns, toolbarFormId, handleRefresh, filterGroups, setFilterGroups, pivotHours, setPivotHours, getBookoutData, selectedData, onFlagStatusChanged, handleScheduleVoided, }) => {
    return (
        <Ribbon>
            {[
                {
                    title: 'Grid',
                    content: <GridActionsGroup
                        gridRef={gridRef}
                        formId={toolbarFormId}
                        handleRefresh={handleRefresh}
                        filterGroups={filterGroups}
                        setFilterGroups={setFilterGroups}
                        pivotHours={pivotHours}
                        setPivotHours={setPivotHours}
                        toggleMWColumns={toggleMWColumns}
                    />
                },
                {
                    title: 'Actions',
                    content: <ActionsGroup
                        getBookoutData={getBookoutData}
                        selectedData={selectedData}
                        onFlagStatusChanged={onFlagStatusChanged}
                        handleScheduleVoided={handleScheduleVoided}
                    />
                },
                {
                    title: 'Filter',
                    content: <ViewForm
                        onSubmit={handleRefresh}
                        formId={toolbarFormId}
                    />
                },
            ]}
        </Ribbon>
    );
}
