import { useCallback } from 'react';
import { PublicClientApplication } from '@azure/msal-browser';
import { StyledEngineProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import { HelmetProvider } from 'react-helmet-async';
import { RouterProvider } from 'react-router/dom';
import { msalConfigAzureAD, userGroups } from './authConfig';
import { DurableFunctionProvider } from './components/DurableFunctionContext/durableFunctionProvider.js';
import { HubProvider } from './components/HubContext/hubProvider.js';
import { IPProvider } from './components/IPContext/IPProvider.js';
import { StyledSnackbar } from './components/StyledSnackbar.js';
import { UserInfoProvider } from './components/UserInfoContext.js';
import { router } from './routeConfig.js';
import './styles/cardStyles.css';
import { ColorThemeProvider } from './styles/ColorMode/ColorThemeProvider.js';
import './styles/gridRowStyles.css';
import './styles/muiStyles.css';
import './styles/powerstationStyles.css';
import { useInactivityLogout } from './utils/useInactivityLogout.js';
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { useUserGroups } from "./data/useUserGroups";

const msalInstanceAzureAD = new PublicClientApplication(msalConfigAzureAD);

msalInstanceAzureAD.handleRedirectPromise().then((response) => {
    if (response !== null) {
        msalInstanceAzureAD.setActiveAccount(response.account);
    }
}).catch((error) => {
    console.error(error);
});

const SnackbarComponents = {
    success: StyledSnackbar,
    error: StyledSnackbar,
    info: StyledSnackbar,
};


function App() {
    const { instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const { userIsInGroup } = useUserGroups();
    const userIsAdmin = userIsInGroup(userGroups.admins);

    const handleLogout = useCallback(() => {
        if (isAuthenticated && !userIsAdmin) {
            instance.logoutRedirect();
        }
    }, [instance, isAuthenticated, userIsAdmin]);

    useInactivityLogout(handleLogout);

    return (
        <HelmetProvider>
            <StyledEngineProvider injectFirst>
                <ColorThemeProvider>
                    <SnackbarProvider maxSnack={5} Components={SnackbarComponents} preventDuplicate>
                        <IPProvider>
                            <UserInfoProvider>
                                <HubProvider>
                                    <DurableFunctionProvider>
                                        <RouterProvider router={router} />
                                    </DurableFunctionProvider>
                                </HubProvider>
                            </UserInfoProvider>
                        </IPProvider>
                    </SnackbarProvider>
                </ColorThemeProvider>
            </StyledEngineProvider>
        </HelmetProvider>
    );
}

export default App;
