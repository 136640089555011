import AddAlertIcon from '@mui/icons-material/AddAlert';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import SignpostIcon from '@mui/icons-material/Signpost';
import StorefrontIcon from '@mui/icons-material/Storefront';
import { Divider, IconButton, List, ListItemButton, ListItemIcon, ListItemText, Toolbar, Tooltip } from "@mui/material";
import MuiDrawer from '@mui/material/Drawer';
import styled from '@mui/system/styled';
import { useState } from "react";

const storageConfig = { name: 'ResizableMarketplaceMenuPane', type: localStorage };

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

export default (props) => {
  const { selected, handleSelection } = props;
  const storageKey = 'marketplace-menu-open';
  const defaultOpen = JSON.parse(localStorage.getItem(storageKey) ?? 'true');
  const [open, setOpen] = useState(defaultOpen);
  let icon;
  
  const toggleDrawer = () => {
    setOpen(!open);
    localStorage.setItem(storageKey, !open);
  };

  if (open) {
    icon = <ChevronLeftIcon />;
  } else {
    icon = <ChevronRightIcon />;
  }

  return(
      <Drawer variant="permanent" open={open}>
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            px: [1],
          }}
        >
          <IconButton onClick={toggleDrawer} size="large">
            {icon}
          </IconButton>
        </Toolbar>
        <Divider />
        <List>
          <Tooltip title="View information about Marketplace" arrow placement="right">
            <ListItemButton 
              selected={selected === 'marketplacehelp'}
              onClick={() => handleSelection('marketplacehelp')}>
              <ListItemIcon>
                <QuestionMarkIcon />
              </ListItemIcon>
              <ListItemText primary="Marketplace Help" />
            </ListItemButton>
          </Tooltip>
        </List>
        <Divider />
        <List>
          <Tooltip title="See all TSRs up for offer." arrow placement="right">
            <ListItemButton 
              selected={selected === 'browseall'}
              onClick={() => handleSelection('browseall')}>
              <ListItemIcon>
                <StorefrontIcon />
              </ListItemIcon>
              <ListItemText primary="Browse All" />
            </ListItemButton>
          </Tooltip>
          <Tooltip title="Set up notifications for when a new posting is added or changes." arrow placement="right">
            <ListItemButton 
              selected={selected === 'notifications'}
              onClick={() => handleSelection('notifications')}>
              <ListItemIcon>
                <AddAlertIcon />
              </ListItemIcon>
              <ListItemText primary="Notifications" />
            </ListItemButton>
          </Tooltip>
          <Tooltip title="Review My Postings." arrow placement="right">
            <ListItemButton 
              selected={selected === 'mypostings'}
              onClick={() => handleSelection('mypostings')}>
              <ListItemIcon>
                <SignpostIcon />
              </ListItemIcon>
              <ListItemText primary="My Postings" />
            </ListItemButton>
          </Tooltip>
        </List>
      </Drawer>
  )
}
