import { RibbonGroupContainer } from "../../Ribbon";
import { EditDealButton, CreateBookoutButton, FlagScheduleButton, VoidScheduleButton, } from "../../RibbonButtons";

export const ActionsGroup = ({ expanded, getBookoutData, selectedData, onFlagStatusChanged, handleScheduleVoided, }) => {
    const toFlag = !selectedData || selectedData.Schedule_Status === 'CONFIRMED';
    const scheduleIsVoided = selectedData?.Schedule_Status === 'VOIDED'

    return (
        <RibbonGroupContainer sx={{ alignItems: 'start' }}>

            <CreateBookoutButton
                expanded={expanded}
                getBookoutData={getBookoutData}
                selectedRow={selectedData}
            />

            <FlagScheduleButton
                expanded={expanded}
                scheduleId={selectedData?.scheduleID}
                toFlag={toFlag}
                onFlagStatusChanged={onFlagStatusChanged}
            />

            <EditDealButton
                expanded={expanded}
                disabled={!selectedData}
                deal={selectedData}
                label={'Edit Deal'}
            />

            <VoidScheduleButton
                selectedSchedule={selectedData}
                onVoid={handleScheduleVoided}
                toVoid={!scheduleIsVoided}
                disabled={!selectedData?.scheduleID}
                expanded={expanded}
                label={scheduleIsVoided ? 'Unvoid Schedule' : 'Void Schedule'}
            />

        </RibbonGroupContainer>
    )
}
