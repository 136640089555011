import { Checkbox, FormControlLabel, Stack, useTheme } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { VisibleColumnPicker } from "../Review/VisibleColumnPicker";
import { CreateBookoutButton, EditDealButton, FlagScheduleButton, VoidScheduleButton, } from "../RibbonButtons";
import { IncludeFlaggedCheckbox } from "../IncludeFlaggedCheckbox";

export const HourlyToolbar = ({ gridRef, pivot, handlePivotChange, getBookoutData, selectedData, onFlagStatusChanged, toggleMWColumns, handleScheduleVoided, }) => {
    const theme = useTheme();
    const toFlag = !selectedData || selectedData.Schedule_Status === 'CONFIRMED';
    const { getValues, watch, setValue } = useFormContext();
    const { showScheduleMW, showDealMW, showTagMW, showPrice } = getValues();
    const defaultVisible = { scheduleMW: showScheduleMW, dealMW: showDealMW, tagMW: showTagMW, price: showPrice, };
    const colKeys = Object.keys(defaultVisible);
    const startDate = watch('startDate');
    const stopDate = watch('stopDate');
    const defaultIncludeFlagged = !!watch('includeFlagged');
    const columnLabels = {
        scheduleMW: 'Schedule MW',
        dealMW: 'Deal MW',
        tagMW: 'Tag MW',
        price: 'Price',
    };

    const handleToggleColumn = (key, visible) => {
        toggleMWColumns(key, visible, startDate, stopDate);
        const formKey = key === 'scheduleMW' ? 'showScheduleMW' : key === 'dealMW' ? 'showDealMW' : key === 'tagMW' ? 'showTagMW' : 'showPrice';
        setValue(formKey, visible);
    }

    const scheduleIsVoided = selectedData?.Schedule_Status === 'VOIDED';

    return (
        <Stack
            direction="row"
            spacing={4}
            sx={{
                paddingRight: theme.spacing(4),
                display: 'flex',
                alignItems: 'center',
            }}
        >

            <FormControlLabel
                onClick={e => e.stopPropagation()}
                control={
                    <Checkbox
                        checked={pivot}
                        onClick={handlePivotChange}
                    />
                }
                label="Pivot Hours"
            />

            <IncludeFlaggedCheckbox
                gridRef={gridRef}
                defaultChecked={defaultIncludeFlagged}
            />

            <CreateBookoutButton
                getBookoutData={getBookoutData}
                selectedRow={selectedData}
                buttonProps={{
                    sx: { p: theme.spacing(1), },
                }}
            />

            <FlagScheduleButton
                scheduleId={selectedData?.scheduleID}
                toFlag={toFlag}
                onFlagStatusChanged={onFlagStatusChanged}
                buttonProps={{
                    sx: { p: theme.spacing(1), },
                }}
            />

            <EditDealButton
                disabled={!selectedData}
                deal={selectedData}
                label={'Edit Deal'}
                sx={{
                    p: theme.spacing(1),
                }}
            />

            <VoidScheduleButton
                selectedSchedule={selectedData}
                onVoid={handleScheduleVoided}
                toVoid={!scheduleIsVoided}
                disabled={!selectedData?.scheduleID}
            />

            <VisibleColumnPicker
                toggleColumn={handleToggleColumn}
                colKeys={colKeys}
                colLabels={columnLabels}
                defaultChecked={defaultVisible}
            />

        </Stack>
    );
};
