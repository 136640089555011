import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { useSnackbar } from 'notistack';
import { useEffect, useRef, useState, useCallback } from "react";
import { hubUrlPrefix } from '../../authConfig';
import dayjs from "dayjs";

export const useBatchedHubAction = ({ createKey, action, allowedMessages, predicate = () => true, wait = 2000, }) => {
    const pendingUpdates = useRef({});
    const timers = useRef({});
    const { enqueueSnackbar } = useSnackbar();
    const [connection, setConnection] = useState();

    useEffect(() => {
        if (!connection) {
            try {
                const newConnection = new HubConnectionBuilder()
                    .withUrl(`${hubUrlPrefix}/hubs/chat/`)
                    .configureLogging(LogLevel.Debug)
                    .withAutomaticReconnect()
                    .build();

                setConnection(newConnection);
                console.log(`Connection Object Created`);
            } catch (error) {
                enqueueSnackbar(`Hub connection error: ${error.message}`);
            }
        }
    }, [enqueueSnackbar, connection]);

    useEffect(() => {
        if (connection) {
            try {
                connection.start().then(result => {
                    console.log('Receive data Connected!');
                }).catch(e => enqueueSnackbar(`Hub connection failed. Detailed error: ${e}`));
            } catch (error) {
                enqueueSnackbar(`Hub connection error: ${error.message}`);
            }
        }
    }, [connection, enqueueSnackbar]);

    const queueAction = useCallback((obj) => {
        if (!allowedMessages.includes(obj.hubMessage) || !predicate(obj)) return;

        const id = createKey(obj);
        pendingUpdates.current[id] = obj;

        //Start a timer to call the action
        if (!timers.current[id]) {
            timers.current[id] = setTimeout(() => {
                console.log(`Sending action for ${id} Timestamp: ${dayjs().format('HH:mm:ss.SSS')}`);
                action(pendingUpdates.current[id]);

                // Clean up
                delete pendingUpdates.current[id];
                delete timers.current[id];
            }, wait);
        }
    }, [action, allowedMessages, predicate, createKey, wait]);

    useEffect(() => {
        if (connection) {
            connection.on('ReceiveMessage', queueAction)
        }
    }, [connection, queueAction]);
}
