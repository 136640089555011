import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Card, CardActionArea, CardContent, CardHeader, Checkbox, IconButton, List, ListItemButton, ListItemIcon, ListItemText, Popover, styled, Tooltip, Typography, useTheme } from '@mui/material';
import { useState } from 'react';

export const VisibleColumnPicker = ({ toggleColumn, colKeys, colLabels, defaultChecked, }) => {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState(null);
    const [checked, setChecked] = useState(defaultChecked);

    const handleShowPopover = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClosePopover = (event) => {
        event.stopPropagation();
        setAnchorEl(null);
    };

    const handleToggle = (key) => (event) => {
        event.stopPropagation();
        const newChecked = { ...checked, [key]: !checked[key] };
        setChecked(newChecked);
        toggleColumn(key, newChecked[key]);
    };

    const open = Boolean(anchorEl);

    return (
        <Card elevation={0}>
            <Tooltip title="Hide /Show different sets of columns in the grid.">
                <CardActionArea onMouseDown={handleShowPopover}>
                    <CardHeader
                        title={<Typography>Columns</Typography>}
                        action={<ExpandMore expand={open} >
                            <ExpandMoreIcon />
                        </ExpandMore>}
                        sx={{
                            py: theme.spacing(0.5),
                            px: theme.spacing(1),
                        }}
                    />
                </CardActionArea>
            </Tooltip>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClosePopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <CardContent
                    sx={{
                        px: 0,
                        '&:last-child': {
                            padding: 0,
                        },
                    }}
                >
                    <List>
                        {colKeys.map((colKey) => (
                            <ListItemButton dense onClick={handleToggle(colKey)} key={colKey}>
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        checked={checked[colKey]}
                                        tabIndex={-1}
                                        disableRipple
                                        sx={{ py: 0, }}
                                    />
                                </ListItemIcon>
                                <ListItemText primary={colLabels[colKey]} />
                            </ListItemButton>
                        ))}
                    </List>
                </CardContent>
            </Popover>
        </Card >
    );
};

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton disableRipple {...other} />;
})(({ theme }) => ({
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
    variants: [
        {
            props: ({ expand }) => !expand,
            style: {
                transform: 'rotate(0deg)',
            },
        },
        {
            props: ({ expand }) => !!expand,
            style: {
                transform: 'rotate(180deg)',
            },
        },
    ],
}));
