import AnalyticsIcon from '@mui/icons-material/Analytics';
import AssessmentIcon from '@mui/icons-material/Assessment';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth';
import CellTowerIcon from '@mui/icons-material/CellTower';
import ConstructionIcon from '@mui/icons-material/Construction';
import EventNoteIcon from '@mui/icons-material/EventNote';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import StormIcon from '@mui/icons-material/Storm';
import SummarizeIcon from '@mui/icons-material/Summarize';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import { Divider, List, ListItemButton, ListItemIcon, ListItemText, Tooltip } from "@mui/material";
import { memo, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { userGroups } from '../../authConfig';
import { useUserGroups } from '../../data/useUserGroups';
import { Drawer } from "../StyledDrawer";
import BalanceIcon from '@mui/icons-material/Balance';
import GradingIcon from '@mui/icons-material/Grading';
import ShareIcon from '@mui/icons-material/Share';

const Menu = (props) => {
    const { view } = props;
    const [openDrawer, setOpenDrawer] = useState(false);
    const navigate = useNavigate();
    const timerRef = useRef(null);
    const { userIsInGroup } = useUserGroups();
    const isPlatinumUser = userIsInGroup(userGroups.dealrizzplatinum);
    const userIsCollaborator = userIsInGroup(userGroups.dealrizzcollaborator);

    function handleSelection(view) {
        navigate(`/dealrizz/${view}`);
    }

    const handleMouseEnter = () => {
        timerRef.current = setTimeout(() => {
            setOpenDrawer(true);
        }, 200);
    };

    const handleMouseLeave = () => {
        clearTimeout(timerRef.current); // Clear the timer if the mouse leaves
        setOpenDrawer(false);
    };

    return (
        <Drawer
            variant="permanent"
            open={openDrawer}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <List>
                <ListItemButton
                    selected={view === 'help'}
                    onClick={() => handleSelection('help')}>
                    <ListItemIcon>
                        <QuestionMarkIcon />
                    </ListItemIcon>
                    <ListItemText primary="Help" />
                </ListItemButton>

                <Divider />

                <ListItemButton
                    selected={view === 'checkout'}
                    onClick={() => handleSelection('checkout')}>
                    <ListItemIcon>
                        <BalanceIcon />
                    </ListItemIcon>
                    <ListItemText primary="Checkout" />
                </ListItemButton>

                <ListItemButton
                    selected={view === 'review'}
                    onClick={() => handleSelection('review')}>
                    <ListItemIcon>
                        <GradingIcon />
                    </ListItemIcon>
                    <ListItemText primary="Review" />
                </ListItemButton>

                {userIsCollaborator && <ListItemButton
                    selected={view === 'collaboration'}
                    onClick={() => handleSelection('collaboration')}>
                    <ListItemIcon>
                        <ShareIcon />
                    </ListItemIcon>
                    <ListItemText primary="Collaboration" />
                </ListItemButton>}

                <Divider />

                <ListItemButton
                    selected={view === 'dealsummary'}
                    onClick={() => handleSelection('dealsummary')}>
                    <ListItemIcon>
                        <SummarizeIcon />
                    </ListItemIcon>
                    <ListItemText primary="Deal Summary" />
                </ListItemButton>

                <ListItemButton
                    selected={view === 'positionreport'}
                    onClick={() => handleSelection('positionreport')}>
                    <ListItemIcon>
                        <AssessmentIcon />
                    </ListItemIcon>
                    <ListItemText primary="Deal Position" />
                </ListItemButton>

                <ListItemButton
                    selected={view === 'positionmanagement'}
                    onClick={() => handleSelection('positionmanagement')}>
                    <ListItemIcon>
                        <ManageSearchIcon />
                    </ListItemIcon>
                    <ListItemText primary="Position MGMT" />
                </ListItemButton>

                {isPlatinumUser && <ListItemButton
                    selected={view === 'lmps'}
                    onClick={() => handleSelection('lmps')}>
                    <ListItemIcon>
                        <PriceCheckIcon />
                    </ListItemIcon>
                    <ListItemText primary="Heatmap" />
                </ListItemButton>}

                <Divider />

                <Tooltip title="Create and adjust tags." arrow placement="right">
                    <ListItemButton
                        selected={view === 'tagreport'}
                        onClick={() => handleSelection('tagreport')}>
                        <ListItemIcon>
                            <LocalOfferIcon />
                        </ListItemIcon>
                        <ListItemText primary="Tagging" />
                    </ListItemButton>
                </Tooltip>

                <Divider />

                <ListItemButton
                    selected={view === 'schedulesummary'}
                    onClick={() => handleSelection('schedulesummary')}>
                    <ListItemIcon>
                        <EventNoteIcon />
                    </ListItemIcon>
                    <ListItemText primary="Schedule Summary" />
                </ListItemButton>

                <ListItemButton
                    selected={view === 'scheduling'}
                    onClick={() => handleSelection('scheduling')}>
                    <ListItemIcon>
                        <CalendarViewMonthIcon />
                    </ListItemIcon>
                    <ListItemText primary="Scheduling" />
                </ListItemButton>

                <ListItemButton
                    selected={view === 'unscheduledmws'}
                    onClick={() => handleSelection('unscheduledmws')}>
                    <ListItemIcon>
                        <CalendarTodayIcon />
                    </ListItemIcon>
                    <ListItemText primary="Unscheduled MWs" />
                </ListItemButton>

                <Divider />

                <Tooltip title="See the details of the TSRs recently queried from OASIS." arrow placement="right">
                    <ListItemButton
                        selected={view === 'tsractivity'}
                        onClick={() => handleSelection('tsractivity')}>
                        <ListItemIcon>
                            <LocalActivityIcon />
                        </ListItemIcon>
                        <ListItemText primary="TSR Activity" />
                    </ListItemButton>
                </Tooltip>

                <ListItemButton
                    selected={view === 'transmissionposition'}
                    onClick={() => handleSelection('transmissionposition')}>
                    <ListItemIcon>
                        <CellTowerIcon />
                    </ListItemIcon>
                    <ListItemText primary="Trans Position" />
                </ListItemButton>

                <ListItemButton
                    selected={view === 'transmissiondealsummary'}
                    onClick={() => handleSelection('transmissiondealsummary')}>
                    <ListItemIcon>
                        <AnalyticsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Trans Deal Summary" />
                </ListItemButton>

                {isPlatinumUser && <ListItemButton
                    selected={view === 'pathbuilder'}
                    onClick={() => handleSelection('pathbuilder')}>
                    <ListItemIcon>
                        <ConstructionIcon />
                    </ListItemIcon>
                    <ListItemText primary="Pathway Builder" />
                </ListItemButton>}

                <Tooltip title="View and edit your transaction alerts." arrow placement="right">
                    <ListItemButton
                        selected={view === 'transactionqueue'}
                        onClick={() => handleSelection('transactionqueue')}>
                        <ListItemIcon>
                            <TrackChangesIcon />
                        </ListItemIcon>
                        <ListItemText primary="Trans Q" />
                    </ListItemButton>
                </Tooltip>

                <Divider />

                <ListItemButton
                    selected={view === 'indexdailyprice'}
                    onClick={() => handleSelection('indexdailyprice')}>
                    <ListItemIcon>
                        <RequestQuoteIcon />
                    </ListItemIcon>
                    <ListItemText primary="Index Daily Price" />
                </ListItemButton>

                <ListItemButton
                    selected={view === 'forecast'}
                    onClick={() => handleSelection('forecast')}>
                    <ListItemIcon>
                        <StormIcon />
                    </ListItemIcon>
                    <ListItemText primary="Forecast" />
                </ListItemButton>

            </List>
        </Drawer>
    )
}

export default memo(Menu);
