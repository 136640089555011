import { Box, Typography } from "@mui/material";

export const CurtailmentCellRenderer = (props) => {
    const { value, data } = props;
    const isCurtailed = data?.TagStatus?.toLowerCase() === "curtailed";
    const discrepancyFlag = data?.tagDiscrepancyFlag;

    let flagClass = '';
    if (isCurtailed && !!discrepancyFlag) {
        flagClass = 'corner-flag-red';
    } else if (isCurtailed) {
        flagClass = 'corner-flag-split';
    }

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                height: '100%',
                width: '100%',
            }}
            className={flagClass}
        >
            <Typography className='ag-cell-text'>{value}</Typography>
        </Box>
    )
}
