import React, { Suspense, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { userGroups } from "../authConfig";
import Collapse from '@mui/material/Collapse';
import GroupGuard from '../components/Guards/GroupGuard';
import SpyMonkeyAppMenu from '../components/SpyMonkey/SpyMonkeyAppMenu';
import '../styles/spyMonkeyStyles.css'
import CriteriaProvider from '../components/SpyMonkey/CriteriaContext/criteriaProvider';
import Stalker from '../components/SpyMonkey/Stalker/Stalker';
import TimedTSRs from '../components/SpyMonkey/TimedTSRs/TimedTSRs';
import TariffPricesExport from '../components/TransNow/TariffPrices';
import Help from '../components/Help';
import TransHunterAuditGrid from '../components/TransHunter/TransHunterAuditGrid';
import SystemData from '../components/SpyMonkey/System Data/SystemData';
import Watcher from '../components/SpyMonkey/Watcher';
import FlexCollapse from '../components/FlexCollapse';

const SpyMonkey = () => {
    const defaultView = localStorage.getItem('spyMonkeyView');
    const [view, setView] = React.useState(defaultView ?? 'spymonkeyhelp');
    const [loadedViews, setLoadedViews] = useState({});

    useEffect(() => {
        if (view && !loadedViews[view]) {
            setLoadedViews({
                ...loadedViews,
                [view]: true
            })
        }
    }, [view]);

    const handleSelection = (viewName) => {
        localStorage.setItem('spyMonkeyView', viewName);
        setView(viewName);
    };

    return (
        <CriteriaProvider>
            <Box sx={{ display: 'flex', height: '100%' }}>
                <SpyMonkeyAppMenu handleSelection={handleSelection} selected={view} />
                <Box
                    className="flex-column"
                    sx={{
                        p: '0px',
                        flexGrow: 1,
                    }}
                >
                    {loadedViews['spymonkeyhelp'] && <Collapse in={view === 'spymonkeyhelp'} key={'spymonkeyhelp_collapse'}>
                        <Help appName={'SpyMonkey'} />
                    </Collapse>}
                    {loadedViews['audit'] && <GroupGuard groups={[userGroups.admins]}>
                        <Collapse in={view === 'audit'} key={'audit_collapse'}>
                            <TransHunterAuditGrid />
                        </Collapse>
                    </GroupGuard>}
                    {loadedViews['stalker'] && <Collapse in={view === 'stalker'} key={'stalker_collapse'}>
                        <Stalker visible={view === 'stalker'} />
                    </Collapse>}
                    {loadedViews['timedtsrs'] && <FlexCollapse in={view === 'timedtsrs'} key={'timedtsrs_collapse'}>
                        <TimedTSRs />
                    </FlexCollapse>}
                    {loadedViews['systemdata'] && <Collapse in={view === 'systemdata'} key={'systemdata_collapse'}>
                        <SystemData />
                    </Collapse>}
                    {loadedViews['tariffprices'] && <FlexCollapse in={view === 'tariffprices'} key={'tariffprices_collapse'}>
                        <TariffPricesExport />
                    </FlexCollapse>}
                    {loadedViews['watcher'] && <Collapse in={view === 'watcher'} key={'watcher_collapse'}>
                        <Watcher />
                    </Collapse>}
                </Box>
            </Box>
        </CriteriaProvider>
    );
}

export default SpyMonkey;
