import { Checkbox, FormControlLabel } from "@mui/material";
import { useState, useEffect, useCallback, } from 'react';
import { useFormContext } from 'react-hook-form';

export const IncludeFlaggedCheckbox = ({ gridRef, checkboxProps, defaultChecked = false }) => {
    const [includeFlagged, setIncludeFlagged] = useState(defaultChecked);
    const { watch } = useFormContext();
    const viewIncludeFlagged = watch('includeFlagged');

    const handleToggleIncludeFlagged = useCallback((e, include) => {
        e?.stopPropagation();
        setIncludeFlagged(include);

        //filter the Status column; if includeFlagged is true, filter to FLAGGED
        //otherwise clear the filter on the Status column, leaving other filters in place
        if (!include) {
            const filterModel = { ...gridRef.current.api.getFilterModel(), };
            filterModel.Schedule_Status = {
                values: ["CONFIRMED"],
                filterType: "set",
            };
            gridRef.current.api.setFilterModel(filterModel);
        } else {
            const filterModel = gridRef.current.api.getFilterModel();
            delete filterModel.Schedule_Status;
            gridRef.current.api.setFilterModel(filterModel);
        }
        gridRef.current.api.onFilterChanged();
    }, [includeFlagged, gridRef]);

    useEffect(() => {
        if (gridRef.current?.api) {
            handleToggleIncludeFlagged(null, viewIncludeFlagged);
        }
    }, [viewIncludeFlagged, gridRef.current?.api]);

    return (
        <FormControlLabel
            onClick={e => e.stopPropagation()}
            control={
                <Checkbox
                    {...checkboxProps}
                    checked={includeFlagged}
                    onClick={(e) => handleToggleIncludeFlagged(e, !includeFlagged)}
                />
            }
            label="Include Flagged"
        />
    );
}
