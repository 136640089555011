import { useTheme } from '@mui/material/styles';
import { AgChartsReact } from 'ag-charts-react';
import dayjs from 'dayjs';

// Generate Sales Data
function createData(hour, amount) {
    return { time: new Date(dayjs().hour(hour)), amount };
}

const data = [
    createData(0, 0),
    createData(3, 300),
    createData(6, 600),
    createData(9, 800),
    createData(12, 1500),
    createData(15, 2000),
    createData(18, 2400),
    createData(21, 2400),
];

export default function Chart() {
    const theme = useTheme();
    const palette = theme.palette.primary;

    const chartOptions = {
        data: data,
        background: {
            fill: theme.palette.mode === 'dark' ? theme.palette.primary.backgroundContrast : theme.palette.primary.white,
        },
        title: {
            text: 'Today',
            fontSize: 18,
        },
        theme: {
            baseTheme: theme.palette.mode === 'light' ? 'ag-material' : 'ag-material-dark',
            palette: {
                fills: [
                    palette.main,
                ],
                strokes: [
                    palette.main,
                ],
            },
        },
        series: [
            {
                type: 'line',
                xKey: 'time',
                yKey: 'amount',
                strokeWidth: 2,
                marker: {
                    size: 2,
                },
            },
        ],
        autoSize: true,
        axes: [
            {
                type: 'time',
                position: 'bottom',
                nice: false,
            },
            {
                type: 'number',
                position: 'left',
                title: {
                    text: 'Capacity (MW)',
                },
            },
        ],
    }

    return (
        <AgChartsReact options={chartOptions} />
    );
}
