import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, IconButton, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import dayjs from "dayjs";
import { useCallback, useMemo, useRef, useState } from "react";
import { useFormContext } from 'react-hook-form';
import { apiUrlPrefix } from "../../authConfig";
import { AgGridContainer } from "../AgGrid/AgGridContainer";
import { defaultColumnDef, defaultGridOptions } from "../AgGrid/defaultGridProps";
import { useApi } from "../useApi";
import useHeader from "../useHeader";
import { isLongDay } from "./Utils";

export const CreateBookoutDialog = ({ open, closeDialog, bookoutData, }) => {
    const contentContainerRef = useRef(null);
    const theme = useTheme();
    const { enqueueSnackbar, logAction, post, get, } = useApi();
    const headers = useHeader();
    const gridRef = useRef();
    const { getValues } = useFormContext();
    const [reduceOriginalDealProfile, setReduceOriginalDealProfile] = useState(true);

    const profileHours = useMemo(() => {
        return Array.from({ length: 24 }, (_, i) => i + 1).reduce((acc, i) => {
            acc.push(i);
            if (i === 2 && isLongDay(bookoutData?.date)) acc.push('2*');
            return acc;
        }, []);
    }, [bookoutData]);

    const currencyFormatter = useCallback((formatOptions) => (params) => {
        const value = params.value;
        const isPriceRow = params.data?.id.toLowerCase().includes('price');
        if (value !== null && value !== undefined) {
            const options = isPriceRow ? { style: 'currency', currency: 'USD' } : formatOptions;
            // Format value as USD currency with commas
            return new Intl.NumberFormat('en-US', options).format(value);
        }
        return value;
    }, []);

    const defaultHourColDef = useCallback((i) => ({
        enableCellChangeFlash: true,
        colId: `${i}`,
        field: `${i}`,
        minWidth: 55,
        headerName: `${i}`,
        aggFunc: 'sum',
        type: 'numericColumn',
        cellDataType: 'number',
        editable: (params) => params.data.type === 'bookout',
        valueFormatter: currencyFormatter(),
    }), [currencyFormatter]);

    const hourlyColDefs = useMemo(() => {
        return profileHours.map(hour => defaultHourColDef(hour));
    }, [defaultHourColDef, profileHours]);

    const colDefs = useMemo(() => [
        {
            field: 'id',
            headerName: 'ID',
            hide: true,
            minWidth: 160,
            cellDataType: 'text',
        },
        {
            field: 'group',
            hide: true,
            rowGroup: true,
        },
        ...hourlyColDefs,
    ], [hourlyColDefs]);

    const defaultColDef = useMemo(() => ({
        ...defaultColumnDef,
        flex: 1,
    }), []);

    const gridOptions = useMemo(() => {
        const typeAndThemeMatch = (type, mode) => {
            return (params) => {
                const rowType = params.data?.type;
                const match = rowType === type && theme.palette.mode === mode;
                return match;
            }
        }

        return {
            rowClassRules: {
                "ag-row-schedule": typeAndThemeMatch('schedule', 'light'),
                "ag-row-schedule-dark": typeAndThemeMatch('schedule', 'dark'),
                "ag-row-deal": typeAndThemeMatch('deal', 'light'),
                "ag-row-deal-dark": typeAndThemeMatch('deal', 'dark'),
                "ag-row-bookout": typeAndThemeMatch('bookout', 'light'),
                "ag-row-bookout-dark": typeAndThemeMatch('bookout', 'dark'),
            },
        }
    }, [theme.palette.mode]);

    const bookoutColorClass = theme.palette.mode === 'light' ? 'ag-row-bookout' : 'ag-row-bookout-dark';
    const dealColorClass = theme.palette.mode === 'light' ? 'ag-row-deal' : 'ag-row-deal-dark';
    const scheduleColorClass = theme.palette.mode === 'light' ? 'ag-row-schedule' : 'ag-row-schedule-dark';

    const calculateRemaining = useCallback((deal, schedules) => {
        const remaining = profileHours.reduce((acc, hour) => {
            const hourlyRemaining = schedules.reduce((acc, schedule) => {
                const scheduleValue = schedule[hour] ?? 0;
                return acc - scheduleValue;
            }, deal[hour] ?? 0);

            acc[hour] = hourlyRemaining;
            return acc;
        }, {});

        return remaining;
    }, [profileHours]);

    const rowData = useMemo(() => {
        if (!bookoutData) return [];

        const existingDealRow = { ...bookoutData.deal, type: 'deal', id: `${bookoutData.deal.id}`, group: 'deal' };
        const dealPriceRow = { ...bookoutData.price, type: 'deal', group: 'dealPrice' };
        const schedules = bookoutData.schedules.map(schedule => ({
            ...schedule,
            type: 'schedule',
            id: `${schedule.id} (Schedule)`,
            group: 'Schedules',
        }));
        const remainingHourly = calculateRemaining(existingDealRow, schedules);
        const remainingRow = { type: 'remaining', id: 'Remaining', ...remainingHourly, group: 'remaining' };
        const newPurchaseRow = { id: 'New Purchase MW', type: 'bookout', ...remainingHourly, group: 'newPurchase' };
        const newSaleRow = { id: 'New Sale MW', type: 'bookout', ...remainingHourly, group: 'newSale' };
        const newScheduleRow = { id: 'New Schedule', type: 'bookout', ...remainingHourly, group: 'newSchedule' };

        const defaultBookoutPrices = profileHours.reduce((acc, hour) => {
            return { ...acc, [hour]: 0, };
        }, {});
        const newPurchasePriceRow = { ...defaultBookoutPrices, type: 'bookout', id: `New Purchase Price`, group: 'purchasePrice' };
        const newSalePriceRow = { ...defaultBookoutPrices, type: 'bookout', id: `New Sale Price`, group: 'salePrice' };

        return [
            existingDealRow,
            dealPriceRow,
            ...schedules,
            remainingRow,
            newPurchaseRow,
            newPurchasePriceRow,
            newSaleRow,
            newSalePriceRow,
            newScheduleRow,
        ];
    }, [bookoutData, calculateRemaining, profileHours]);

    const getRowId = useCallback(params => `${params.data.id}`, []);

    const flowDate = dayjs(bookoutData?.date).format('MM/DD/YYYY');

    const handleSubmitBookout = async (event) => {
        closeDialog(event); // close the dialog and clear selections

        const bookoutData = [];
        let dealID = '';
        gridRef.current.api.forEachNode(node => {
            if (node.data?.type === 'bookout' || node.data?.type === 'deal') {
                if (node.data?.type === 'deal' && node.data?.group === 'deal') {
                    dealID = node.data.id;
                }

                const data = node.data;
                const row = { ...data };
                delete row.group;
                delete row.type;
                bookoutData.push(row);
            }
        });

        const { timezone } = getValues();

        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Push?name=dealrizz.UI_scheduleBookoutInsert_v3`
            + `&parm=${headers.userGuid}`
            + `&parm=${dealID ?? ''}`
            + `&parm=${flowDate ?? ''}`
            + `&parm=${timezone ?? ''}`
            + `&parm=${reduceOriginalDealProfile ? 1 : 0}`

        return post(url, bookoutData).then(response => {
            if (response.status === 200) {
                enqueueSnackbar('Bookout saved successfully.', { variant: 'success' });
                logAction('User inserted bookout', 'Deal Rizz Mid Office', bookoutData);
            }
        });
    }

    const autoGroupColDef = useMemo(() => ({
        headerName: 'Type',
        valueGetter: (params) => {
            if (params.data?.type === 'deal') {
                const data = params.data;
                const value = data.dealName ? `${data.dealName} (${data.id})` : data.id;
                return value;
            } else {
                return params.data?.id;
            }
        },
        minWidth: 200,
    }), []);

    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth={false}
            ref={contentContainerRef}
            onClick={(e) => e.stopPropagation()}
        >
            <DialogTitle sx={{ pb: 0, pt: 1, px: 2 }}>
                <Stack spacing={4} direction='row' sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant='h6'>Create Bookout on {flowDate}</Typography>

                    <Box sx={{ flexGrow: 1, }} />

                    <Stack direction="row" alignItems="center" spacing={1}>
                        <Box className={dealColorClass} sx={{ width: 20, height: 20, borderWidth: '1px', borderStyle: 'solid' }} />
                        <Typography align='center'>- Deal</Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <Box className={scheduleColorClass} sx={{ width: 20, height: 20, borderWidth: '1px', borderStyle: 'solid' }} />
                        <Typography align='center'>- Schedule</Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <Box className={bookoutColorClass} sx={{ width: 20, height: 20, borderWidth: '1px', borderStyle: 'solid' }} />
                        <Typography align='center'>- Bookout</Typography>
                    </Stack>
                    <IconButton onClick={closeDialog} size='large'>
                        <CloseIcon />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent className='flex-column' sx={{ overflowX: 'hidden', px: 2, }}>
                <AgGridContainer style={{ paddingY: theme.spacing(1), }}>
                    <AgGridReact
                        {...defaultGridOptions}
                        autoGroupColumnDef={autoGroupColDef}
                        ref={gridRef}
                        columnDefs={colDefs}
                        defaultColDef={defaultColDef}
                        rowData={rowData}
                        domLayout={'autoHeight'}
                        gridOptions={gridOptions}
                        getRowId={getRowId}
                        //groupDisplayType={'groupRows'}
                        groupDefaultExpanded={1}
                        suppressAggFuncInHeader
                        groupRemoveSingleChildren
                    />
                </AgGridContainer>
            </DialogContent>
            <DialogActions sx={{ display: 'flex', pt: 0 }}>
                <Button onClick={closeDialog} color='error'>Cancel</Button>

                <Box sx={{ flexGrow: 1 }} />

                <Tooltip title="Reduce the new deal by the amount of the new schedule." arrow>
                    <FormControlLabel
                        onClick={e => e.stopPropagation()}
                        control={
                            <Checkbox
                                checked={reduceOriginalDealProfile}
                                onClick={() => setReduceOriginalDealProfile(r => !r)}
                            />
                        }
                        label="Reduce Original Deal Profile"
                    />
                </Tooltip>

                <Button color='primary' variant='contained' onClick={handleSubmitBookout}>Submit Bookout</Button>
            </DialogActions>
        </Dialog>
    );
}
