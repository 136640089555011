import { useMemo, useEffect, useState, useRef, useCallback } from 'react';
import _ from 'lodash';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css'
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { useLocalGuid } from '../../data/UserGuidContext/useLocalGuid';
import { apiUrlPrefix, } from '../../authConfig';
import { DivGuard } from "../Guards/DivGuardFlex";
import { userGroups } from "../../authConfig";
import { useGridButtons } from '../useGridButtons'
import { AgGridReact, } from 'ag-grid-react';
import { useData } from '../useData';
import useGridLayout from '../useGridLayout';
import { columnPanel, filterPanel } from '../ToolPanels/DefaultToolPanels';
import { LayoutToolPanel } from '../ToolPanels/LayoutToolPanel';
import { useSnackbar } from "notistack";
import useHeader from '../useHeader';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import axios from 'axios';
import { useHubMessages } from '../HubContext/useHubMessages';
import { AgGridContainer } from '../AgGrid/AgGridContainer';
import { useColumnTypes } from "../AgGrid/useColumnTypes"
import ImageDetailCellRenderer from '../ImageDetailCellRenderer';
import { useAzureBlobs } from '../useAzureBlobs';
import { useImageUploader } from '../useImageUploader';
import { useUserGroups } from '../../data/useUserGroups';

const SupportRequests = () => {
    const guid = useLocalGuid();
    const { enqueueSnackbar } = useSnackbar();
    const loadUri = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_SupportRequestsFetch&parm=${guid}`;
    const saveUri = `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Push?name=PowerStationMetaData.UI_SupportRequestsSave&parm=${guid}`;
    const storageLocation = 'support-requests-grid-saved-layout'
    const gridRef = useRef();
    const { fetch, saveData } = useData();
    const { RefreshButton, UndoButton, RedoButton, ExportButton, SaveButton, CopyRowsButton, AutoSizeButton } = useGridButtons({ gridRef });
    const [severityLevels, setSeverityLevels] = useState({ values: [] });
    const [typeLevels, setTypeLevels] = useState({ values: [] });
    const [statusLevels, setStatusLevels] = useState({ values: [] });
    const headers = useHeader();
    const { blobServiceClient, uploadBlob, createContainer, } = useAzureBlobs(process.env.REACT_APP_AZURE_BLOB_IMAGE_CONTAINER_NAME);
    const { handleUpdateFiles, clearImages, } = useImageUploader();
    dayjs.extend(utc);
    const { diffData, message } = useHubMessages();
    const inputRef = useRef(null);
    const [loaded, setLoaded] = useState(false);
    const { userIsInGroup } = useUserGroups();
    const isAdmin = userIsInGroup(userGroups.admins);
    const { columnTypes } = useColumnTypes();

    const gridOptions = {
        // [...]
        rowClassRules: {
            "row-fail": params => !params.api.getValue("status", params.node) && params.api.getValue("severity", params.node) === 'Unknown',
            "row-pass": params => params.api.getValue("status", params.node) === 'In Development',
            "row-waiting": params => params.api.getValue("status", params.node) === 'Future Enhancement'
        },
    };

    const onRefreshBtn = () => {
        fetch(loadUri).then(response => {
            handleDataUpdate(response.data ?? []);
            enqueueSnackbar('Support tickets refreshed...');
        })
    };

    useEffect(() => {
        if (message === 'PowerStationMetaData.UI_SupportRequestsSave' && gridRef.current?.api) {
            console.log(`Support Requests Save Message Received. ${dayjs().format('HH:mm:ss.SSS')}`)
            fetch(loadUri).then(response => {
                handleDataUpdate(response.data ?? []);
            });
        }
    }, [message]);

    useEffect(() => {
        Promise.allSettled([fetchSeverityLevels(), fetchStatusLevels(), fetchRequestTypes()]);
    }, []);

    useEffect(() => {
        if (typeLevels.values.length && statusLevels.values.length && severityLevels.values.length) {
            loadLayout();
        }
    }, [typeLevels, severityLevels, statusLevels]);

    async function handleDataUpdate(data) {
        const start = dayjs();
        const oldData = [];
        gridRef.current.api.forEachNode(node => oldData.push(node.data));

        data.forEach(row => {
            Object.keys(row).forEach(field => {
                if (!row[field]) {
                    row[field] = ' ';
                }
            })
        })

        const { toAdd, toUpdate, diffStart, } = diffData(data, oldData, 'supportRequestID');

        const toAddRowMap = new Map(toAdd.map(row => [row.supportRequestID.toString(), row]));
        const toUpdateRowMap = new Map(toUpdate.map(row => [row.supportRequestID.toString(), row]));
        const containers = blobServiceClient.listContainers({ prefix: 'ticket-' });
        for await (const container of containers) {
            const containerId = container.name.split('-')[1];
            if (toAddRowMap.has(containerId)) {
                toAddRowMap.get(containerId).hasImages = true;
            } else if (toUpdateRowMap.has(containerId)) {
                toUpdateRowMap.get(containerId).hasImages = true;
            }
        }

        console.log(`Support Requests data data diff took ${dayjs().diff(diffStart)}ms.`);

        setTimeout(() => {
            gridRef.current.api.applyTransaction({
                add: toAdd,
                addIndex: 0,
                update: toUpdate,
            });
        }, 0);

        console.log(`Support Requests data update took ${dayjs().diff(start)}ms total.`);
    }

    async function fetchSeverityLevels() {
        const options = {
            method: 'GET',
            headers: headers,
            url: `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_SupportRequestsSeverityFetch`,
        }

        return axios(options).then(response => {
            setSeverityLevels({ values: response?.data.map(level => level.severity) ?? [] });
        }).catch(error => {
            enqueueSnackbar(`Error loading severity levels. Message: ${error}`)
        });
    }

    async function fetchStatusLevels() {
        const options = {
            method: 'GET',
            headers: headers,
            url: `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_SupportRequestsStatusFetch`,
        }

        return axios(options).then(response => {
            setStatusLevels({ values: response?.data.map(level => level.status) ?? [] });
        }).catch(error => {
            enqueueSnackbar(`Error loading status levels. Message: ${error}`)
        });
    }

    async function fetchRequestTypes() {
        const options = {
            method: 'GET',
            headers: headers,
            url: `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_SupportRequestsTypeFetch`,
        }

        return axios(options).then(response => {
            setTypeLevels({ values: response?.data.map(level => level.type) ?? [] });
        }).catch(error => {
            enqueueSnackbar(`Error loading request types. Message: ${error}`)
        });
    }

    function onCellValueChanged(params) {
        const newRowData = params.node.data;
        newRowData['changed'] = true;
        saveData(saveUri, `Update to ticket ${newRowData.supportRequestID} saved.`, newRowData);
    }

    function formatDate(params) {
        const val = params.value ? dayjs.utc(params.value).local().format('MM/DD/YYYY HH:mm') : params.value;
        return val;
    }

    const severityComparator = useMemo(() => {
        const order = severityLevels.values;
        return function (valueA, valueB) {
            const mapValue = (value) => value === 'Unknown' ? 'Medium' : value;
            const indexA = order.indexOf(mapValue(valueA));
            const indexB = order.indexOf(mapValue(valueB));
            if (indexA > -1 && indexB > -1) {
                return indexA - indexB;
            } else if (indexA > -1) {
                return -1;
            } else if (indexB > -1) {
                return 1;
            } else {
                return valueA.localeCompare(valueB);
            }
        };
    }, [severityLevels.values]);

    const baseColDefs = [
        {
            checkboxSelection: true,
            editable: false,
            headerName: "ID",
            headerCheckboxSelection: true,
            cellRenderer: 'agGroupCellRenderer',
            field: "supportRequestID",
            type: 'numericColumn',
            cellDataType: 'number',
            filter: 'agNumberColumnFilter',
        },
        {
            headerName: "Title",
            field: "title",
        },
        {
            headerName: "Description",
            field: "description",
            wrapText: true,
            tooltipValueGetter: params => params.value,
        },
        {
            headerName: "Type",
            field: "type",
            cellEditor: 'agRichSelectCellEditor',
            cellEditorParams: typeLevels,
            cellEditorPopup: true,
        },
        {
            headerName: "Priority",
            field: "severity",
            cellEditor: 'agRichSelectCellEditor',
            cellEditorParams: severityLevels,
            cellEditorPopup: true,
            comparator: severityComparator,
        },
        {
            editable: false,
            headerName: "Submitted By",
            field: "userName",
        },
        {
            headerName: "Status",
            field: "status",
            cellEditor: 'agRichSelectCellEditor',
            cellEditorParams: statusLevels,
            cellEditorPopup: true,
        },
        {
            headerName: "Notes",
            wrapText: true,
            field: "notes",
            tooltipValueGetter: params => params.value,
        },
        {
            headerName: "Resolution",
            wrapText: true,
            field: "resolution",
            tooltipValueGetter: params => params.value,
        },
        {
            headerName: "Assigned To",
            field: "asignedTo",
        },
        {
            editable: false,
            headerName: "Date Created",
            field: "utcDataCreated",
            type: 'dateColumn',
            filter: 'agDateColumnFilter',
            valueFormatter: formatDate,
        },
    ]

    function onGridReady(params) {
        fetch(loadUri).then(response => {
            handleDataUpdate(response.data ?? []);
            gridRef.current.api.onFilterChanged();
        }).catch(error => {
            enqueueSnackbar(`Error fetching grid data. Message: ${error}`);
        });
    }

    function getRowNodeId(params) {
        return params.data.supportRequestID;
    }

    const defaultColDefs = useMemo(() => ({
        editable: isAdmin,
        floatingFilter: true,
        filter: "agMultiColumnFilter",
        sortable: true,
        resizable: true,
    }), [isAdmin])

    const { layoutPanel, colDefs, loadLayout, applySavedFilters, } = useGridLayout(storageLocation, gridRef, baseColDefs, defaultColDefs);

    const sideBar = useMemo(() => {
        return {
            toolPanels: [
                columnPanel,
                filterPanel,
                layoutPanel,
            ],
            position: 'right',
        }
    }, [columnPanel, filterPanel, layoutPanel]);

    const detailCellRenderer = useMemo(() => {
        return (props) => ImageDetailCellRenderer(props);
    }, []);

    function ticketHasImages(rowData) {
        return rowData.hasImages;
    }

    const handleUploadImages = (event) => {
        const rowParams = inputRef.current.rowParams;
        const newRowData = rowParams.node.data;
        handleUpdateFiles(event).then(images => {
            const newContainerName = `ticket-${newRowData.supportRequestID}`;
            createContainer(newContainerName).then(response => {
                images.forEach(image => {
                    uploadBlob(image, newContainerName).then(response => {
                        enqueueSnackbar(`Image ${image.name} uploaded.`, { variant: 'success' });
                    });
                });
            });
            const commaSeparatedImageNames = images.map(image => image.name).join(',');
            newRowData.imageFileNames = commaSeparatedImageNames + `${newRowData.imageFileNames ? ',' + newRowData.imageFileNames : ''}`;
            newRowData.hasImages = true;
            newRowData.changed = true;
            saveData(saveUri, `Images added to ticket ${newRowData.supportRequestID}.`, newRowData);
        }).finally(() => {
            clearImages();
        });
    };

    function handleImageUpload(params) {
        inputRef.current.rowParams = params;
        inputRef.current?.click();
    }

    const getContextMenuItems = useCallback((params) => [
        'cut',
        'copy',
        'copyWithHeaders',
        'copyWithGroupHeaders',
        'paste',
        {
            name: 'Add Image',
            action: () => handleImageUpload(params),
        },
        'separator',
        'export',
    ], []);

    function applyFilters() {
        !loaded && applySavedFilters();
        setLoaded(true);
    }

    return (
        <DivGuard groups={[userGroups.support]} >
            <AgGridContainer
                style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1,
                }}
            >
                <Box sx={{ display: 'flex', p: 1 }}>
                    <Tooltip title="Refresh the list of support requests." arrow placement="top">
                        <RefreshButton onClick={onRefreshBtn} />
                    </Tooltip>&nbsp;
                    <Tooltip title="Undo the last edit made." arrow placement="top">
                        <UndoButton />
                    </Tooltip>&nbsp;
                    <Tooltip title="Redo the last edit made." arrow placement="top">
                        <RedoButton />
                    </Tooltip>&nbsp;
                    <Tooltip title="Copy the currently selected rows to the clipboard." arrow placement="top">
                        <CopyRowsButton />
                    </Tooltip>&nbsp;
                    <Tooltip title="Download the grid in CSV format to open in Excel." arrow placement="top">
                        <ExportButton />
                    </Tooltip>&nbsp;
                    <Tooltip title="Autosize All Columns." arrow placement="top">
                        <AutoSizeButton />
                    </Tooltip>&nbsp;
                </Box>
                <input
                    type="file"
                    ref={input => inputRef.current = input}
                    name='img'
                    multiple
                    onChange={handleUploadImages}
                    accept='image/*'
                    hidden
                />
                <AgGridReact
                    ref={gridRef}
                    columnDefs={colDefs}
                    getRowId={getRowNodeId}
                    onGridReady={onGridReady}
                    onCellValueChanged={onCellValueChanged}
                    onRowDataUpdated={applyFilters}
                    suppressRowClickSelection
                    enableCellChangeFlash
                    enableRangeSelection={true}
                    enableFillHandle={true}
                    undoRedoCellEditing={true}
                    undoRedoCellEditingLimit={20}
                    gridOptions={gridOptions}
                    sideBar={sideBar}
                    rowHeight={50}
                    animateRows={true}
                    masterDetail
                    tooltipShowDelay={500}
                    tooltipHideDelay={100000}
                    isRowMaster={ticketHasImages}
                    getContextMenuItems={getContextMenuItems}
                    detailCellRenderer={'detailCellRenderer'}
                    columnTypes={columnTypes}
                    components={{
                        layoutToolPanel: LayoutToolPanel,
                        detailCellRenderer: detailCellRenderer,
                    }}
                />
            </AgGridContainer>
        </DivGuard>
    );
};

export default SupportRequests;
