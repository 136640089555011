import { yupResolver } from '@hookform/resolvers/yup';
import { FormControlLabel, FormGroup, TextField, Tooltip } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import FormAutocomplete from "../../FormControls/FormAutocomplete";
import FormCheckbox from "../../FormControls/FormCheckbox";
import { useLookupValues } from '../DealEntry/LookupContext';

export const AddNewOrUpdateForm = (props) => {
    const { handleAddNew, viewToUpdate, defaultValues, allViewNames = [], } = props;
    const { lookupValues } = useLookupValues();

    const schema = yup.object().shape({
        label: yup.string().required('Label is required.').test(
            'is-unique',
            'View label already exists. Please choose a unique label.',
            value => !allViewNames.includes(value)
        ).max(50, 'Label must be less than 50 characters.'),
        timezone: yup.string().required('Timezone is required'),
        counterparty: yup.string().required('Counterparty is required'),
        transaction: yup.string().required('Transaction is required'),
        book: yup.string().required('Book is required'),
    });

    const { handleSubmit, formState: { errors }, control, reset, register } = useForm({
        resolver: yupResolver(schema),
        defaultValues,
        mode: 'onBlur',
    });

    useEffect(() => {
        reset(defaultValues);
    }, [defaultValues, reset]);

    function onSubmit(data) {
        handleAddNew(data);
    }

    const counterpartyOptions = useMemo(() => {
        const parties = lookupValues.filter(lookup => lookup.lookupField === 'Counterparty').map(lookup => lookup.value);
        return ['All', ...new Set(parties)];
    }, [lookupValues]);

    const transactionValues = useMemo(() => {
        const transactions = lookupValues.filter(lookup => lookup.lookupField === 'Transaction').map(lookup => lookup.value);
        return ['All', ...new Set(transactions)];
    }, [lookupValues]);

    const bookOptions = useMemo(() => {
        const transactions = lookupValues.filter(lookup => lookup.lookupField === 'Book').map(lookup => lookup.value);
        return ['All', ...new Set(transactions)];
    }, [lookupValues]);

    return (
        <form id='deal-rizz-checkout-add-or-update-form' onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3} alignItems='center' sx={{ p: 1, }}>
                <Grid size={12}>
                    <Tooltip title="Label for the view." arrow placement="top">
                        <TextField
                            {...register('label')}
                            color="primary"
                            label="Label"
                            size='small'
                            error={!!errors.label}
                            helperText={errors.label?.message}
                            fullWidth
                        />
                    </Tooltip>
                </Grid>

                <Grid size={3}>
                    <FormAutocomplete
                        autoHighlight
                        fullWidth
                        options={['Pacific Standard Time', 'Eastern Standard Time', 'Central Standard Time', 'Mountain Standard Time']}
                        getOptionLabel={(option) => option.split(' ')[0]}
                        control={control}
                        name='timezone'
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Prevailing Timezone"
                                size='small'
                                color="success"
                                error={!!errors.timezone}
                                helperText={errors.timezone?.message}
                                sx={{ minWidth: 150, }}
                            />
                        )}
                    />
                </Grid>

                <Grid size={3}>
                    <FormAutocomplete
                        options={counterpartyOptions}
                        control={control}
                        name="counterparty"
                        disableClearable
                        fullWidth
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Counterparty"
                                size='small'
                                error={!!errors.counterparty}
                                helperText={errors.counterparty?.message}
                            />
                        )}
                    />
                </Grid>

                <Grid size={3}>
                    <FormAutocomplete
                        options={transactionValues}
                        control={control}
                        name="transaction"
                        disableClearable
                        fullWidth
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Transaction"
                                size='small'
                                error={!!errors.transaction}
                                helperText={errors.transaction?.message}
                            />
                        )}
                    />
                </Grid>

                <Grid size={3}>
                    <FormAutocomplete
                        options={bookOptions}
                        control={control}
                        name="book"
                        disableClearable
                        fullWidth
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Book"
                                size='small'
                                error={!!errors.book}
                                helperText={errors.book?.message}
                            />
                        )}
                    />
                </Grid>

                <Grid size={2}>
                    <FormGroup sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', }}>
                        <FormControlLabel
                            label="Filter Groups: "
                            labelPlacement="start"
                            control={<FormCheckbox
                                name={'filterGroups'}
                                control={control}
                            />}
                        />
                    </FormGroup>
                </Grid>

                <Grid size={2}>
                    <FormGroup sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', }}>
                        <FormControlLabel
                            label="Pivot Hours: "
                            labelPlacement="start"
                            control={<FormCheckbox
                                name={'pivotHours'}
                                control={control}
                            />}
                        />
                    </FormGroup>
                </Grid>

                <Grid size={2}>
                    <FormGroup sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', }}>
                        <FormControlLabel
                            label="Include Flagged: "
                            labelPlacement="start"
                            control={<FormCheckbox
                                name={'includeFlagged'}
                                control={control}
                            />}
                        />
                    </FormGroup>
                </Grid>


                {viewToUpdate?.id && <Grid size={6} >
                    <FormGroup sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', }}>
                        <FormControlLabel
                            label="Share View: "
                            labelPlacement="start"
                            control={<FormCheckbox
                                name={'shared'}
                                control={control}
                            />}
                        />
                    </FormGroup>
                </Grid>}

                <Grid size={2}>
                    <FormGroup sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', }}>
                        <FormControlLabel
                            label="Show Schedule MW: "
                            labelPlacement="start"
                            control={<FormCheckbox
                                name={'showScheduleMW'}
                                control={control}
                            />}
                        />
                    </FormGroup>
                </Grid>


                <Grid size={2}>
                    <FormGroup sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', }}>
                        <FormControlLabel
                            label="Show Deal MW: "
                            labelPlacement="start"
                            control={<FormCheckbox
                                name={'showDealMW'}
                                control={control}
                            />}
                        />
                    </FormGroup>
                </Grid>

                <Grid size={2}>
                    <FormGroup sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', }}>
                        <FormControlLabel
                            label="Show Tag MW: "
                            labelPlacement="start"
                            control={<FormCheckbox
                                name={'showTagMW'}
                                control={control}
                            />}
                        />
                    </FormGroup>
                </Grid>

            </Grid>
        </form>
    );
}
