import { useState, useMemo, forwardRef, useContext, useEffect, } from "react";
import { useActionAudit } from "../useActionAudit";
import { Box, Collapse, Button, Grid, Stack, Typography, Fade } from '@mui/material';
import { TransmissionDialog } from "./TransmissionDialog";
import PublishIcon from '@mui/icons-material/Publish';
import AddAlertIcon from '@mui/icons-material/AddAlert';
import CornerRibbon from '../CornerRibbon';
import { userGroups } from '../../authConfig';
import MapIcon from '@mui/icons-material/Map';
import ProGrid from './East/ProGridEast';
import PathMap from '../PathMap';
import _ from 'lodash'
import SavedSearchIcon from '@mui/icons-material/SavedSearch';
import AddLabelDialog from "./Dashboard/AddLabelDialog";
import { useSnackbar } from "notistack";
import RefreshIcon from '@mui/icons-material/Refresh';
import { UserContext } from "../../data/fetchUserGroups";
import { useUserGroups } from "../../data/useUserGroups";
import { AgGridContainer } from "../AgGrid/AgGridContainer";
import TabIcon from '@mui/icons-material/Tab';
import { Link } from "react-router";

export default forwardRef((props, ref) => {
    const { logAction } = useActionAudit();
    const { data, criteria, handleCancelFetch, handleSaveToDashboard, } = props;
    const [selectedPath, setSelectedPath] = useState();
    const [selectedNodes, setSelectedNodes] = useState([]);
    const [openTransmissionDialog, setOpenTransmissionDialog] = useState(false);
    const [dialogAction, setDialogAction] = useState('');
    const [showMap, setShowMap] = useState(false);
    const [openAddLabelDialog, setOpenAddLabelDialog] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const { userIsInGroup, } = useUserGroups();
    const dashboardUser = userIsInGroup(userGroups.fastpathpro, userGroups.fastpathdashboard);

    useEffect(() => {
        //set up a broadcast channel to send the data to the map.
        const bc = new BroadcastChannel('fastpathmap');
        bc.postMessage({ data, pors: criteria.por, pods: criteria.pod, });
        bc.close();
    }, [data]);

    const gridWidth = useMemo(() => showMap ? '40%' : '100%', [showMap])

    function handleSaveNewView(formData) {
        setOpenAddLabelDialog(false);
        handleSaveToDashboard({ label: formData.label, ...criteria }).then(response => {
            enqueueSnackbar('View saved to dashboard.', { variant: 'success' });
        })
    }

    function handleShowMapInNewTab() {
        setShowMap(false);

        //set up a broadcast channel to send the data to the map.
        //the map will send a message back when it is ready to receive the data.
        const bc = new BroadcastChannel('fastpathmap');
        bc.onmessage = function (ev) {
            if (ev.data === 'ready') {
                bc.postMessage({ data, pors: criteria.por, pods: criteria.pod, });
            }
        }
    }

    return (
        <Box
            sx={{ p: 2, width: '100%', height: '100%', }}
            overflow='auto'
            className='flex-column'
        >
            <AddLabelDialog open={openAddLabelDialog} handleClose={() => setOpenAddLabelDialog(false)} handleSave={handleSaveNewView} />
            <CornerRibbon
                message={<pre>                      Search results are limited in demo version.                      </pre>}
                buttonText={'Click here to request upgrade'}
                href="https://webforms.pipedrive.com/f/5X4yAVMERh0y8mGszS6ntpwcPhTToxMyqwqNXhrZXfn7xl0R187lTPeBzlp0B4jxCP"
                groups={[userGroups.fastpathpro]}
            />
            {openTransmissionDialog && <TransmissionDialog
                open={openTransmissionDialog}
                pathData={selectedPath}
                action={dialogAction}
                setAction={setDialogAction}
                handleClose={() => setOpenTransmissionDialog(false)}
                searchCriteria={criteria}
            />}
            <Stack spacing={2} direction='row' sx={{ p: 1 }}>
                <Button
                    endIcon={<PublishIcon />}
                    variant='contained'
                    onClick={() => {
                        setOpenTransmissionDialog(true);
                        setDialogAction('reservenow');
                    }}
                    disabled={!selectedPath}
                >Reserve</Button>
                <Button
                    endIcon={<AddAlertIcon />}
                    variant='contained'
                    onClick={() => {
                        setOpenTransmissionDialog(true);
                        setDialogAction('textalert');
                    }}
                    disabled={!selectedPath}
                >Alert</Button>
                {dashboardUser && <Button
                    endIcon={<SavedSearchIcon />}
                    variant='contained'
                    onClick={() => setOpenAddLabelDialog(true)}
                    disabled={!criteria.por || !criteria.pod}
                >Save to Dashboard</Button>}
                <Box sx={{ flexGrow: 1, }} />
                <Button
                    endIcon={<MapIcon />}
                    variant='contained'
                    onClick={() => {
                        setShowMap(show => !show);
                    }}
                >{showMap ? 'Hide Map' : 'View Map'}</Button>
            </Stack>
            <Stack direction={'row'} spacing={2} sx={{ height: '100%' }}>
                <AgGridContainer
                    style={{ width: gridWidth, height: '100%' }}
                >
                    <ProGrid
                        data={data}
                        ref={ref}
                        setSelectedRow={setSelectedPath}
                        handleCancelFetch={handleCancelFetch}
                    />
                </AgGridContainer>
                <div style={{ width: '60%', display: showMap ? 'block' : 'none', position: 'relative' }}>
                    <Button
                        style={{ position: 'absolute', top: 2, right: 2, zIndex: 9999, }}
                        variant="contained"
                        size="small"
                        endIcon={<TabIcon />}
                        component={Link}
                        to="/fastpathmap"
                        target="_blank"
                        onClick={handleShowMapInNewTab}
                        disabled={!data.length}
                    >Show In New Tab</Button>
                    <PathMap
                        data={data}
                        pors={criteria.por}
                        pods={criteria.pod}
                        selectedPath={selectedPath}
                        setSelectedNodes={setSelectedNodes}
                        selectedNodes={selectedNodes}
                    />
                </div>
            </Stack>
        </Box >
    )
})
