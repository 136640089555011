import { AddNewOrUpdateDialog } from "./AddNewOrUpdateDialog";
import { DashboardLazy } from "../Dashboard/DashboardLazy.js";
import { CollaborationView } from "./CollaborationView";

export const CollaborationDashboard = ({ visible, }) => {
    const dashboardKey = 'collaboration-dashboard';
    const useShared = true;

    return (
        <DashboardLazy
            dashboardKey={dashboardKey}
            useShared={useShared}
            ViewComponent={CollaborationView}
            AddNewOrUpdateDialog={AddNewOrUpdateDialog}
            visible={visible}
        />
    );
}
