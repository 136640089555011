import { useEffect, useCallback, } from "react";
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { debounce } from "lodash";

export const ViewContextProvider = ({ schema, defaults, children, onSubmit, fieldsToIgnore }) => {
    const methods = useForm({
        resolver: yupResolver(schema),
        defaultValues: defaults,
        mode: 'onBlur',
    });

    const { handleSubmit, watch, reset, } = methods;

    const onSubmitDebounced = useCallback(debounce(onSubmit, 100), [onSubmit]);

    useEffect(() => {
        const subscription = watch((_, { name, }) => {
            if (fieldsToIgnore?.includes(name)) return;
            handleSubmit(onSubmitDebounced)();
        });
        return () => subscription.unsubscribe();
    }, [handleSubmit, watch, onSubmitDebounced, fieldsToIgnore]);

    useEffect(() => {
        reset(defaults);
    }, [defaults, reset]);

    return (
        <FormProvider {...methods} onSubmit={onSubmit}>
            {children}
        </FormProvider>
    );
}
