import React, { useMemo, useEffect, useState, useRef, forwardRef } from 'react';
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css'
import '../../styles/realTimeStyles.css'
import { Box, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import Tooltip from '@mui/material/Tooltip';
import axios from 'axios';
import { apiUrlPrefix, subscriptionKey, userGroups } from '../../authConfig';
import { useLocalGuid } from '../../data/UserGuidContext/useLocalGuid';
import { columnPanel, filterPanel } from '../ToolPanels/DefaultToolPanels';
import { useGridButtons } from '../useGridButtons';
import { HeadsetMicSharp } from '@mui/icons-material';
import useHeader from '../useHeader';
import { AgGridReact } from 'ag-grid-react';
import useGridLayout from '../useGridLayout';
import { useData } from '../useData';
import { LayoutToolPanel } from '../ToolPanels/LayoutToolPanel';
import { AgGridContainer } from '../AgGrid/AgGridContainer';

export default forwardRef((props, ref) => {
    const { enqueueSnackbar } = useSnackbar();
    const guid = useLocalGuid();
    const { RefreshButton, ExportButton, RemoveSelectedButton, SaveButton } = useGridButtons({ ref });
    const [buttonsDisabled, setButtonsDisabled] = useState(true);
    const headers = useHeader();
    const storageLocation = 'real-time-userCertificates-layout'
    const { rowData, getData } = useData([]);
    const loadUri = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_UserCertificatesFetch&parm=${guid}`;
    const [selectedRows, setSelectedRows] = useState([]);

    useEffect(() => {
        let abortController = new AbortController();
        // your async action is here  
        getData(loadUri);
        return () => {
            abortController.abort();
        }
    }, []);

    const onRefreshBtn = () => {
        getData(loadUri);
        let message = 'User certificate mappings refreshing...';
        enqueueSnackbar(message);
    };

    async function onSaveDataButtonClick() {
        ref.current.api.stopEditing();
        setButtonsDisabled(true);
        ref.current.api.showLoadingOverlay();
        let message = 'Submitting certificate mappings...';
        enqueueSnackbar(message);

        message = 'Certifciate mappings sent to power station database...';

        const rowData = [];

        ref.current.api.forEachNode(function (node) {
            const newData = node.data
            newData['isSelected'] = node.isSelected();
            rowData.push(newData);
        })

        const response = await axios.post(
            `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Push?name=PowerStationMetaData.UI_CertificateMappingsSave&parm=${guid}`,
            JSON.stringify(rowData),
            {
                headers: headers,
            }
        ).catch(err => function () { message = `Status: ${err.response?.status}. Message: ${err}` });

        onRefreshBtn();

        ref.current.api.hideOverlay();

        enqueueSnackbar(message);
        setButtonsDisabled(false);
    }

    const gridOptions = {
        // [...]
        rowClassRules: {
            "row-refused": params => params.api.getValue("Status", params.node) === 'REFUSED',
            "row-confirmed": params => params.api.getValue("Status", params.node) === 'CONFIRMED',
            "row-queued": params => params.api.getValue("Status", params.node) === 'QUEUED',
            "row-annulled": params => params.api.getValue("Status", params.node) === 'ANNULLED',
            "row-rejected": params => params.api.getValue("Status", params.node) === 'REJECTED',
            "row-accepted": params => params.api.getValue("Status", params.node) === 'ACCEPTED',
        },

    };

    const statusBar = useMemo(() => {
        return {
            statusPanels: [
                { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
                { statusPanel: 'agTotalRowCountComponent', align: 'left' },
                { statusPanel: 'agSelectedRowCountComponent', align: 'left' },
                { statusPanel: 'agAggregationComponent', align: 'right' },
            ],
        };
    }, []);

    function onRowDataUpdated(params) {
        const rowNum = params.api.rowModel.rowsToDisplay.length;
        setButtonsDisabled(rowNum === 0);
    }

    function onRemoveSelected() {
        const selectedData = ref.current.api.getSelectedRows();
        const res = ref.current.api.applyTransaction({ remove: selectedData });
    };

    function onExport() {
        ref.current.api.exportDataAsCsv();
    };

    const baseColDefs = [
        {
            checkboxSelection: true,
            editable: false,
            headerName: "User",
            headerCheckboxSelection: true,
            initialWidth: 150,
            field: "userName",
        },
        {
            editable: false,
            headerName: "Guid",
            initialWidth: 100,
            field: "userGuid",
        },
        {
            editable: false,
            headerName: "First",
            initialHide: "true",
            initialWidth: 100,
            field: "firstName",
        },
        {
            editable: false,
            headerName: "Last",
            initialWidth: 100,
            initialHide: "true",
            field: "lastName",
        },
        {
            editable: false,
            headerName: "Market",
            initialWidth: 100,
            initialHide: "false",
            field: "Market",
        },
        {
            editable: false,
            headerName: "Thumbprint",
            initialHide: "false",
            initialWidth: 100,
            field: "certificateThumbprint",
        },
        {
            editable: true,
            headerName: "OASIS User Name",
            initialHide: "false",
            initialWidth: 100,
            field: "user",
        },
        {
            editable: true,
            headerName: "OASIS Password",
            initialHide: "false",
            initialWidth: 100,
            field: "encryptedPwd",
        },
        {
            editable: true,
            headerName: "URL",
            initialHide: "false",
            cellEditor: 'agRichSelectCellEditor',
            cellEditorParams: {
                values: ['https://demoapp.oasis.oati.com/', 'https://app.oasis.oati.com/',],
                searchDebounceDelay: 500
            },
            cellEditorPopup: true,
            initialWidth: 100,
            field: "url",
        },
        {
            editable: false,
            headerName: "Description",
            initialHide: "false",
            initialWidth: 100,
            field: "description",
        },
    ];

    const { colDefs, layoutPanel, loadLayout } = useGridLayout(storageLocation, ref, baseColDefs);

    const userCertificateSideBar = {
        toolPanels: [
            columnPanel,
            filterPanel,
            layoutPanel,
        ],
        position: 'right',
    };

    const buttonVariant = "contained";
    const buttonColor = "primary";
    const buttonSize = "small";

    const defaultColDef = useMemo(() => ({
        editable: false,
        filter: "agMultiColumnFilter",
        floatingFilter: true,
        sortable: true,
        resizable: true,
    }), [])

    function onGridReady(params) {
        loadLayout();
    }

    function onSelectionChanged(params) {
        const rows = params.api.getSelectedRows();
        setSelectedRows(rows);
    }

    return (
        <AgGridContainer
            style={{
                height: "100%",
                width: "100%",
            }}
        >
            <Box sx={{ display: 'flex', p: 1 }}>
                <Stack alignItems='center' spacing={2} direction='row'>
                    <Tooltip title="Refresh the grid of user certificates." arrow placement="top">
                        <RefreshButton onClick={onRefreshBtn} />
                    </Tooltip>
                    <Tooltip title="Download the grid in CSV format to open in Excel." arrow placement="top">
                        <Button
                            onClick={onExport}
                            endIcon={<DownloadForOfflineOutlinedIcon />}
                            id="csv"
                            variant={buttonVariant}
                            color={buttonColor}
                            size={buttonSize}
                        >CSV</Button>
                    </Tooltip>
                    <Tooltip title="Delete the currently selected row." arrow placement="top">
                        <Button
                            onClick={onRemoveSelected}
                            endIcon={<DeleteIcon />}
                            id="delete"
                            variant={buttonVariant}
                            color={buttonColor}
                            size={buttonSize}
                            disabled={!selectedRows?.length}
                        >Remove</Button>
                    </Tooltip>
                    <Tooltip title="Save all certificate mappings." arrow placement="top">
                        <SaveButton onClick={onSaveDataButtonClick} disabled={buttonsDisabled} />
                    </Tooltip>
                </Stack>
            </Box>
            <AgGridReact
                ref={ref}
                columnDefs={colDefs}
                onGridReady={onGridReady}
                enterMovesDownAfterEdit={true}
                statusBar={statusBar}
                overlayNoRowsTemplate={'<span>No user certificate mappings. Use the templates to create a new user to certificate mapping.</span>'}
                rowSelection="multiple"
                enableFillHandle={true}
                undoRedoCellEditing={true}
                undoRedoCellEditingLimit={20}
                defaultColDef={defaultColDef}
                sideBar={userCertificateSideBar}
                gridOptions={gridOptions}
                tooltipShowDelay={0}
                onRowDataUpdated={onRowDataUpdated}
                onSelectionChanged={onSelectionChanged}
                enableRangeSelection={true}
                suppressRowClickSelection={true}
                animateRows={true}
                rowData={rowData}
                groupSelectsChildren={true}
                components={{
                    layoutToolPanel: LayoutToolPanel,
                }}
            />
        </AgGridContainer>
    );
});
