import VisibilityIcon from '@mui/icons-material/Visibility';
import { debounce } from 'lodash';
import { useLocalGuid } from '../../data/UserGuidContext/useLocalGuid';
import { apiUrlPrefix } from '../../authConfig';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import useHeader from '../useHeader';
import 'ag-grid-community/styles/ag-grid.css'
import { RowDetailsToolPanel } from '../ToolPanels/DetailsToolPanel';
import { LayoutToolPanel } from '../ToolPanels/LayoutToolPanel.js';
import { AgGridReact, } from 'ag-grid-react';
import { useState, useMemo, useEffect, useRef, useCallback } from 'react';
import TsrActivityToolbar from './TsrActivityToolbar';
import _ from 'lodash';
import useGridLayout from '../useGridLayout';
import useHubAction from '../HubContext/useHubAction';
import { stringIntComparator } from '../../utils/gridFunctions';
import AlertDialog from './AlertDialog';
import { tooltipClasses, styled, Divider, List, ListItem, Box, Tooltip, } from '@mui/material';
import dayjs from 'dayjs';
import { useColumnTypes } from '../AgGrid/useColumnTypes';
import { AgGridContainer } from '../AgGrid/AgGridContainer';

const TSRActivityGrid = (props) => {
    const { view, visible, } = props;
    const guid = useLocalGuid();
    const headers = useHeader();
    const { enqueueSnackbar } = useSnackbar();
    const loadUri = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_tsrActivityWithProfiles&parm=${guid}`;
    const gridRef = useRef();

    const storageLocation = `tsr-activity-grid-${view.id}`;
    const [rowDetails, setRowDetails] = useState({});
    const [showDetails, setShowDetails] = useState(false);
    const [openTriggerOnChangeDialog, setOpenTriggerOnChangeDialog] = useState(false);
    const [selected, setSelected] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [autoRefresh, setAutoRefresh] = useState(true);
    const newRows = useRef();
    const [loaded, setLoaded] = useState(false);
    const { columnTypes } = useColumnTypes();

    useEffect(() => {
        if (gridRef.current && loaded) {
            retrieveTsrs();
        }
    }, [view]);

    const fetchDebounced = useCallback(debounce(() => {
        silentRetrieveTsrs();
    }, 2000, { leading: true, }), [view, gridRef.current])

    useHubAction(fetchDebounced, ['PowerStationMetaData.tsr_v2_insertUpdate'], (visible && autoRefresh && gridRef.current), [view]);

    function onCellFocused(e) {
        const i = e.rowIndex;
        const row = e.api.rowModel.rowsToDisplay[i];

        if (row) {
            const colDefs = e.columnApi.columnModel.columnDefs;
            const data = Object.keys(row.data).reduce((data, colKey) => {
                const def = colDefs.find(def => def.field === colKey);
                if (def) {
                    if (def.valueFormatter) {
                        data[def.headerName] = def.valueFormatter({ value: row.data[colKey] })
                    } else {
                        data[def.headerName] = row.data[colKey];
                    }
                }
                return data;
            }, {})

            setRowDetails(data);
            const show = e.api.isToolPanelShowing('details');
            setShowDetails(show);
        }
    };

    function tooltipRenderer(params) {
        const { xValue, yValue } = params;
        const tzDate = dayjs(xValue).format('MM/DD/YY HH:mm');
        return {
            title: tzDate,
            conte: yValue
        }
    }

    const gridOptions = {
        rowClassRules: {
            "row-refused": params => params.data?.Status === 'REFUSED',
            "row-confirmed": params => params.data?.Status === 'CONFIRMED',
            "row-queued": params => params.data?.Status === 'QUEUED',
            "row-invalid": params => params.data?.Status === 'INVALID',
            "row-study": params => params.data?.Status === 'STUDY'
        },
    };

    const defaultColDef = useMemo(() => ({
        editable: false,
        sortable: true,
        filter: "agMultiColumnFilter",
        resizable: true,
        floatingFilter: true,
        enableRowGroup: true,
    }), []);

    function dateComparator(date1, date2) {
        return dayjs(date1).diff(dayjs(date2));
    }

    const DarkTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: 'rgba(1, 1, 1, .95)',
            color: 'white',
            boxShadow: theme.shadows[5],
            fontSize: 12,
        },
    }));

    const ArefCellRenderer = props => {
        const alertIds = props.data?.ALERT_IDS?.split(',');
        const info = JSON.parse(props.data?.alertInfo ?? '[]');
        return (
            <span>
                <span>{props.value}</span>
                {alertIds && (
                    <DarkTooltip arrow placement='right' title={
                        <div>
                            <Box sx={{ pt: 2, }}>
                                <Divider>Alert Info</Divider>
                                {info.map(alert => <><List dense>
                                    <ListItem>ID: {alert.id}</ListItem>
                                    <ListItem>User: {alert.userName}</ListItem>
                                </List>
                                    <Divider /></>)}
                            </Box>
                        </div>
                    }>
                        <VisibilityIcon sx={{ pl: 1, }} fontSize="inherit" />
                    </DarkTooltip>
                )}
            </span>
        )
    }

    const baseColDefs = useMemo(() => [
        {
            checkboxSelection: true,
            headerName: "ARef",
            field: "AssignmentRef",
            comparator: stringIntComparator,
            cellRenderer: 'arefCellRenderer',
        },
        {
            headerName: "Time Queued",
            initialHide: false,
            field: "TimeQueued",
            type: 'dateColumn',
            comparator: dateComparator,
        },
        {
            headerName: "Executed At",
            initialHide: true,
            enableCellChangeFlash: true,
            field: "ExecutedAt",
        },
        {
            headerName: "Triggered At",
            initialHide: true,
            field: "TriggeredAt",
            enableCellChangeFlash: true,
        },
        {
            headerName: "Customer Code",
            initialHide: false,
            field: "CustomerCode",
        },
        {
            headerName: "Request Type",
            initialHide: false,
            field: "RequestType",
        },
        {
            headerName: "Status",
            initialHide: false,
            tooltipField: "SELLER_COMMENTS",
            field: "Status",
            enableCellChangeFlash: true,
        },
        {
            headerName: "Comments",
            initialHide: true,
            field: "SELLER_COMMENTS",
            enableCellChangeFlash: true,
        },
        {
            headerName: "Provider",
            field: "Provider",
        },
        {
            headerName: "POR",
            field: "PointOfReceipt",
        },
        {
            headerName: "POD",
            field: "PointOfDelivery",
        },
        {
            headerName: "Volume",
            field: "Capacity",
        },
        {
            headerName: "Start",
            field: "StartTime",
            type: 'dateColumn',
            comparator: dateComparator,
        },
        {
            headerName: "Stop",
            field: "StopTime",
            type: 'dateColumn',
            comparator: dateComparator,
        },
        {
            headerName: "Current NFATC",
            field: "currentNFATC",
            initialHide: true,
            enableCellChangeFlash: true,
        },
        {
            //field: "atc",
            headerName: "Profile",
            cellRenderer: 'agSparklineCellRenderer',
            equals: _.isEqual,
            initialHide: true,
            enableRowGroup: false,
            minWidth: 400,
            //flex: 1,
            valueGetter: (params) => {
                if (params.data?.capacityProfile) {
                    const atcData = JSON.parse(params.data.capacityProfile).map(xyArray => [
                        new Date(xyArray[0]),
                        xyArray[1]
                    ]);
                    return atcData;
                } else {
                    return [];
                }
            },
            cellRendererParams: {
                sparklineOptions: {
                    type: 'area',
                    axis: {
                        type: 'time',
                    },
                    tooltip: {
                        renderer: tooltipRenderer
                    }
                },
            },
        },
        {
            //field: "atc",
            headerName: "Competition",
            cellRenderer: 'agSparklineCellRenderer',
            enableCellChangeFlash: true,
            initialHide: true,
            enableRowGroup: false,
            minWidth: 400,
            equals: _.isEqual,
            //flex: 1,
            initialHide: false,
            valueGetter: (params) => {
                if (params.data?.historicalTraffic) {
                    const trafficData = JSON.parse(params.data.historicalTraffic).map(xyArray => [
                        new Date(xyArray[0]),
                        xyArray[1]
                    ]);
                    return trafficData;
                } else {
                    return [];
                }
            },
            cellRendererParams: {
                sparklineOptions: {
                    type: 'area',
                    axis: {
                        type: 'time',
                    },
                    tooltip: {
                        renderer: tooltipRenderer
                    }
                },
            },
        },
        {
            //field: "atc",
            headerName: "NFATC",
            cellRenderer: 'agSparklineCellRenderer',
            enableCellChangeFlash: true,
            initialHide: true,
            enableRowGroup: false,
            equals: _.isEqual,
            minWidth: 400,
            //flex: 1,
            valueGetter: (params) => {
                if (params.data?.NFATC) {
                    const atcData = JSON.parse(params.data.NFATC).map(xyArray => [
                        new Date(xyArray[0]),
                        xyArray[1]
                    ]);
                    return atcData;
                } else {
                    return [];
                }
            },
            cellRendererParams: {
                sparklineOptions: {
                    type: 'area',
                    axis: {
                        type: 'time',
                    },
                    tooltip: {
                        renderer: tooltipRenderer
                    }
                },
            },
        },
        {
            //field: "atc",
            headerName: "FATC",
            cellRenderer: 'agSparklineCellRenderer',
            enableCellChangeFlash: true,
            equals: _.isEqual,
            minWidth: 400,
            enableRowGroup: false,
            initialHide: true,
            //flex: 1,
            valueGetter: (params) => {
                if (params.data?.FATC) {
                    const atcData = JSON.parse(params.data.FATC).map(xyArray => [
                        new Date(xyArray[0]),
                        xyArray[1]
                    ]);
                    return atcData;
                } else {
                    return [];
                }
            },
            cellRendererParams: {
                sparklineOptions: {
                    type: 'area',
                    axis: {
                        type: 'time',
                    },
                    tooltip: {
                        renderer: tooltipRenderer
                    }
                },
            },
        },
        {
            headerName: "Seller Code",
            initialHide: false,
            field: "SellerCode",
        },
        {
            headerName: "Preconfirmed",
            initialHide: true,
            field: "Preconfirmed",
        },
        {
            headerName: "Source",
            field: "Source",
            initialHide: true,
        },
        {
            headerName: "Sink",
            initialHide: true,
            field: "Sink",
        },
        {
            headerName: "Path Name",
            field: "PathName",
            initialHide: false,
        },
        {
            headerName: "System Element",
            field: "SYSTEM_ELEMENT",
            initialHide: true,
        },
        {
            headerName: "Service Inc",
            initialHide: true,
            field: "ServiceIncrement",
        },
        {
            headerName: "Class",
            initialHide: true,
            field: "TSClass",
        },
        {
            headerName: "Type",
            initialHide: true,
            field: "TSType",
        },
        {
            headerName: "Period",
            initialHide: true,
            field: "TSPeriod",
        },
        {
            headerName: "Window",
            initialHide: true,
            field: "TSWindow",
        },
        {
            headerName: "Subclass",
            initialHide: true,
            field: "TSSubclass",
        },
        {
            headerName: "Ceiling Price",
            field: "CeilingPrice",
        },
        {
            headerName: "Offer Price",
            field: "OfferPrice",
        },
        {
            headerName: "Bid Price",
            initialHide: true,
            field: "BidPrice",
        },
        {
            headerName: "Price Unit",
            initialHide: true,
            field: "PriceUnit",
        },
        {
            headerName: "Sale Ref",
            initialHide: true,
            field: "SaleRef",
        },
        {
            headerName: "Request Ref",
            initialHide: true,
            field: "RequestRef",
        },
        {
            headerName: "Deal Ref",
            initialHide: true,
            field: "DealRef",
        },
        {
            headerName: "PostingRef",
            initialHide: true,
            field: "PostingRef",
        },
        {
            headerName: "Reassigned Ref",
            initialHide: true,
            field: "ReassignedRef",
            enableCellChangeFlash: true,
        },
        {
            headerName: "Reassigned Capacity",
            initialHide: true,
            field: "ReassignedCapacity",
        },
        {
            headerName: "Reassigned Start",
            initialHide: true,
            field: "ReassignedStartTime",
            type: 'dateColumn',
            comparator: dateComparator,
        },
        {
            headerName: "Reassigned Stop",
            initialHide: true,
            field: "ReassignedStopTime",
            type: 'dateColumn',
            comparator: dateComparator,
        },
        {
            headerName: "Related Ref",
            field: "RelatedRef",
        },
        {
            headerName: "Seller Ref",
            initialHide: true,
            field: "SellerRef",
        },
        {
            headerName: "Reservation Profile Flag",
            initialHide: true,
            field: "ReservationProfileFlag",
        },
        {
            headerName: "Affiliate Flag",
            initialHide: true,
            field: "AffiliateFlag",
        },
        {
            headerName: "NERC Priority",
            field: "NERCcurtailmentPriority",
        },
        {
            headerName: "NP Flag",
            initialHide: true,
            field: "NegotiatedPriceFlag",
        },
        {
            headerName: "Response Time Limit",
            initialHide: true,
            field: "ResponseTimeLimit",
            type: 'dateColumn',
            comparator: dateComparator,
        },
        {
            headerName: "Last Update",
            initialHide: true,
            field: "TimeOfLastUpdate",
            enableCellChangeFlash: true,
            type: 'dateColumn',
            comparator: dateComparator,
        },
        {
            headerName: "Capacity Granted",
            initialHide: true,
            enableCellChangeFlash: true,
            field: "CapacityGranted",
        },
        {
            headerName: "Impacted",
            enableCellChangeFlash: true,
            initialHide: true,
            field: "Impacted",
        },
        {
            headerName: "Competing Request Flag",
            initialHide: true,
            field: "CompetingRequestFlag",
        },
        {
            headerName: "primaryProviderApproval",
            initialHide: true,
            field: "PrimaryProviderApproval",
        },
        {
            headerName: "primaryProviderProvisions",
            initialHide: true,
            field: "PrimaryProviderProvisions",
        },
        {
            headerName: "Rollover Waived",
            initialHide: true,
            field: "RolloverWaived",
        },
        {
            headerName: "CGStatus",
            initialHide: true,
            field: "CGStatus",
        },
        {
            headerName: "CGDeadline",
            initialHide: true,
            field: "CGDeadline",
            type: 'dateColumn',
            comparator: dateComparator,
        },
    ], []);

    const { layoutPanel, colDefs, loadLayout } = useGridLayout(storageLocation, gridRef, baseColDefs);

    const sideBar = useMemo(() => {
        return {
            toolPanels: [
                {
                    id: 'columns',
                    labelDefault: 'Columns',
                    labelKey: 'columns',
                    iconKey: 'columns',
                    toolPanel: 'agColumnsToolPanel',
                    minWidth: 225,
                    maxWidth: 225,
                    width: 225
                },
                {
                    id: 'filters',
                    labelDefault: 'Filters',
                    labelKey: 'filters',
                    iconKey: 'filter',
                    toolPanel: 'agFiltersToolPanel',
                    minWidth: 180,
                    maxWidth: 400,
                    width: 250
                },
                layoutPanel,
                {
                    id: 'details',
                    labelDefault: 'Details',
                    labelKey: 'details',
                    //iconKey: '',
                    toolPanel: 'detailsToolPanel',
                    toolPanelParams: { rowData: rowDetails },
                    minWidth: 470,
                    maxWidth: 490,
                    width: 490
                }
            ],
            position: 'right',
            //defaultToolPanel: 'filters'
        }
    }, [rowDetails]);

    const statusBar = useMemo(() => {
        return {
            statusPanels: [
                { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
                { statusPanel: 'agTotalRowCountComponent', align: 'left' },
                { statusPanel: 'agSelectedRowCountComponent', align: 'left' },
                { statusPanel: 'agAggregationComponent', align: 'right' },
            ],
        };
    }, []);

    function onRowDataUpdated(params) {
        if (newRows.current?.length && loaded) {
            const nodes = newRows.current.map(id => params.api.getRowNode(id)).filter(node => node);
            params.api.flashCells({
                rowNodes: nodes,
                flashDelay: 1000,
                fadeDelay: 2000,
            });
            newRows.current = undefined;
        }
    }

    function getRowNodeId(params) {
        return params.data.ID;
    }

    function silentRetrieveTsrs() {
        console.log(`TSRs retrieved silently at ${dayjs().format('HH:mm:ss.SSS')}.`)
        fetch().then(response => {
            if (response) {
                handleDataUpdate(response.data ?? []);
            }
        });
    }

    async function fetch() {
        const uri = `${loadUri}&parm=${encodeURIComponent(view.timezone)}`
            + `&parm=${view.requestType ?? ''}`
            + `&parm=${view.Provider ?? ''}`
            + `&parm=${view.POR ?? ''}`
            + `&parm=${view.POD ?? ''}`
            + `&parm=${view.customerCode ?? ''}`
            + `&parm=${view.Service_Increment ?? ''}`
            + `&parm=${view.serviceClass ?? ''}`
            + `&parm=${view.startDate ?? ''}`
            + `&parm=${view.startHour ?? ''}`
            + `&parm=${view.stopDate ?? ''}`
            + `&parm=${view.stopHour ?? ''}`

        const options = {
            method: 'GET',
            headers: headers,
            url: uri,
        }

        return axios(options).catch(error => {
            enqueueSnackbar(`Error loading data for a grid from ${uri}. ${error.response?.data} Status: ${error.response?.status}. Message: ${error}`)
            gridRef.current?.api.hideOverlay();
            setIsLoading(false);
        });
    }

    function retrieveTsrs() {
        gridRef.current.api.showLoadingOverlay();
        setIsLoading(true);

        fetch().then(response => {
            handleDataUpdate(response.data ?? [])
            enqueueSnackbar(`${view.label} TSR data retrieved.`);
            gridRef.current.api.hideOverlay();
            setIsLoading(false);
        })
    }

    function handleDataUpdate(newData) {
        if (!gridRef.current) { //If we don't have a grid api available, wait a second and try again; then fail gracefully. I've only seen this condition arise when saving code changes in dev, never under normal operating circumstances. -erik
            let hasNoGridApi = true;
            setTimeout(() => {
                hasNoGridApi = !!gridRef.current;
            }, 1000)
            if (hasNoGridApi) {
                return;
            }
        }
        const timestamp = dayjs();
        /*const oldData = [];
        gridRef.current.api.forEachNode(node => oldData.push(node.data)); 
        const toAddOrUpdate = diffData(newData, oldData);
        const toDeleteOrUpdate = diffData(oldData, newData);
        const toUpdate = toAddOrUpdate.filter(row => toDeleteOrUpdate.find(oldRow => oldRow.ID === row.ID));
        const toAdd = diffData(toAddOrUpdate, toUpdate);
        const toDelete = toDeleteOrUpdate.filter(row => !toUpdate.find(updateRow => updateRow.ID === row.ID));
	
        console.log(`TSR Activity data diff complete. ${dayjs().diff(timestamp)}ms elapsed.`)
	
        newRows.current = toAdd.map(row => row.ID);
        gridRef.current.api.applyTransaction({
          addIndex: 0,
          add: toAdd,
          update: toUpdate,
          remove: toDelete,
        })*/

        const oldData = [];
        gridRef.current.api.forEachLeafNode(node => oldData.push(node.data));

        const newIdHash = newData.reduce((hash, next) => {
            hash[next.ID] = next;
            return hash;
        }, {})

        const oldIdHash = oldData.reduce((hash, next) => {
            hash[next.ID] = next;
            return hash;
        }, {})

        const toAdd = newData.reduce((adding, next) => {
            if (!oldIdHash[next.ID]) { //if the old data doesn't have this ID, it's a new row
                adding.push(next);
            }
            return adding;
        }, [])

        const toUpdate = newData.reduce((updating, next) => {
            if (oldIdHash[next.ID] && !_.isEqual(newIdHash[next.ID], oldIdHash[next.ID])) { //if the old data also has this ID and the data changed, update it
                updating.push(next);
            }
            return updating;
        }, [])

        const toDelete = oldData.reduce((deleting, next) => {
            if (!newIdHash[next.ID]) { //if the new data doesn't have this ID, delete the row
                deleting.push(next);
            }
            return deleting;
        }, [])

        console.log(`TSR Activity data diff complete. ${dayjs().diff(timestamp)}ms elapsed.`)

        gridRef.current.api.applyTransaction({
            add: toAdd,
            addIndex: 0,
            update: toUpdate,
            remove: toDelete,
        });

        console.log(`TSR Activity grid update complete. ${dayjs().diff(timestamp)}ms elapsed.`)
        setLoaded(true);
        gridRef.current.api.refreshCells({ columns: ['AssignmentRef'], force: true })
    }

    const onGridReady = () => {
        if (gridRef.current) {
            silentRetrieveTsrs();
        }
        loadLayout();
    }

    function handleTriggerClick() {
        setOpenTriggerOnChangeDialog(true);
    }

    function clearSelected() {
        gridRef.current.api.deselectAll();
    }

    function onSelectionChange(params) {
        const selectedRows = gridRef.current.api.getSelectedRows();
        setSelected(selectedRows);
    }

    const handleCloseAlertDialog = useCallback(() => {
        setOpenTriggerOnChangeDialog(false);
    }, []);

    return (
        <AgGridContainer
            style={{
                width: "100%", display: 'flex', flexDirection: 'column', flex: 1,
            }}
        >
            <AlertDialog
                open={openTriggerOnChangeDialog}
                closeDialog={handleCloseAlertDialog}
                tsr={selected[0]}
            />
            <TsrActivityToolbar
                onRefresh={retrieveTsrs}
                clearSelected={clearSelected}
                selectedRows={selected}
                disableAllButtons={isLoading}
                handleTriggerClick={handleTriggerClick}
                autoRefresh={autoRefresh}
                setAutoRefresh={setAutoRefresh}
                timezone={view.timezone}
            />
            <AgGridReact
                ref={gridRef}
                onGridReady={onGridReady}
                columnDefs={colDefs}
                getRowId={getRowNodeId}
                onRowDataUpdated={onRowDataUpdated}
                defaultColDef={defaultColDef}
                gridOptions={gridOptions}
                sideBar={sideBar}
                statusBar={statusBar}
                enableCellTextSelection={true}
                paginationAutoPageSize={true}
                groupDefaultExpanded={1}
                enableFillHandle={true}
                undoRedoCellEditing={true}
                undoRedoCellEditingLimit={20}
                onSelectionChanged={onSelectionChange}
                enableRangeSelection={true}
                rowSelection='multiple'
                onCellFocused={onCellFocused}
                animateRows={true}
                tooltipShowDelay={0}
                groupSelectsChildren={true}
                columnTypes={columnTypes}
                components={{
                    detailsToolPanel: RowDetailsToolPanel,
                    layoutToolPanel: LayoutToolPanel,
                    arefCellRenderer: ArefCellRenderer,
                }}
            />
        </AgGridContainer>
    )
}

export default TSRActivityGrid;
