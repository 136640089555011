import { useTheme } from "@mui/material"
import { AgGridReact } from "ag-grid-react"
import dayjs from "dayjs"
import { forwardRef, useCallback, useMemo } from "react"
import { useFormContext } from "react-hook-form"
import { useColorMode } from "../../../styles/ColorMode/useColorMode"
import { AgGridContainer } from "../../AgGrid/AgGridContainer"
import { defaultColumnDef, defaultGridOptions } from "../../AgGrid/defaultGridProps"
import { useColumnTypes } from "../../AgGrid/useColumnTypes"
import { columnPanel, filterPanel } from "../../ToolPanels/DefaultToolPanels"
import { LayoutToolPanel } from "../../ToolPanels/LayoutToolPanel"
import { useDashboardLayout } from "../useDashboardLayout"

export const DailyGrid = forwardRef(({ context, getRowId, onSelectionChanged, }, ref) => {
    const { columnTypes } = useColumnTypes({ dateFormat: 'MM/DD/YYYY' });
    const layoutStorageKey = `deal-rizz-checkout-daily-grid-layout-${context.id}`;
    const { getValues } = useFormContext();
    const theme = useTheme();
    const rowData = useMemo(() => {
        return [];
    }, []);

    const tooltipValueGetter = (params) => {
        const discrepancy = params.data?.discrepancy;
        if (discrepancy) {
            return `Discrepancy: ${discrepancy}`;
        } else {
            return '';
        }
    };

    const baseColDefs = useMemo(() => ([
        {
            headerName: 'Date',
            filter: 'agDateColumnFilter',
            filterParams: {
                comparator: function (filterValue, cellValue) {
                    if (dayjs(cellValue).isBefore(filterValue, 'day')) {
                        return -1;
                    } else if (dayjs(cellValue).isAfter(filterValue, 'day')) {
                        return 1;
                    } else {
                        return 0;
                    }
                },
            },
            valueFormatter: params => {
                return params.value ? dayjs(params.value).format('MM/DD/YYYY') : '';
            },
            checkboxSelection: true,
            valueGetter: (params) => {
                return dayjs(params.data.date).format('MM/DD/YYYY');
            },
        },
        {
            field: 'MW Purchased',
            type: 'numericColumn',
            cellDataType: 'number',
            filter: 'agNumberColumnFilter',
            valueFormatter: (params) => {
                const value = params.value;
                if (value !== null && value !== undefined) {
                    // Format value as USD currency with commas
                    return new Intl.NumberFormat('en-US').format(value);
                }
                return value;
            },
        },
        {
            field: 'MW Sold',
            type: 'numericColumn',
            cellDataType: 'number',
            filter: 'agNumberColumnFilter',
            valueFormatter: (params) => {
                const value = params.value;
                if (value !== null && value !== undefined) {
                    // Format value as USD currency with commas
                    return new Intl.NumberFormat('en-US').format(value);
                }
                return value;
            },
        },
        {
            field: 'Net MW',
            type: 'numericColumn',
            cellDataType: 'number',
            filter: 'agNumberColumnFilter',
            valueFormatter: (params) => {
                const value = params.value;
                if (value !== null && value !== undefined) {
                    // Format value as USD currency with commas
                    return new Intl.NumberFormat('en-US').format(value);
                }
                return value;
            },
        },
        {
            field: 'Energy Purchased',
            type: 'numericColumn',
            cellDataType: 'number',
            filter: 'agNumberColumnFilter',
            valueFormatter: (params) => {
                const value = params.value;
                if (value !== null && value !== undefined) {
                    // Format value as USD currency with commas
                    return new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD'
                    }).format(value);
                }
                return value;
            },
        },
        {
            field: 'Energy Sold',
            type: 'numericColumn',
            cellDataType: 'number',
            filter: 'agNumberColumnFilter',
            valueFormatter: (params) => {
                const value = params.value;
                if (value !== null && value !== undefined) {
                    // Format value as USD currency with commas
                    return new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD'
                    }).format(value);
                }
                return value;
            },
        },
        {
            field: 'Net Energy',
            type: 'numericColumn',
            cellDataType: 'number',
            filter: 'agNumberColumnFilter',
            valueFormatter: (params) => {
                const value = params.value;
                if (value !== null && value !== undefined) {
                    // Format value as USD currency with commas
                    return new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD'
                    }).format(value);
                }
                return value;
            },
        },
        {
            field: 'discrepancy',
            headerName: 'Discrepancies',
            initialHide: true,
        },
    ]), []);

    const defaultColDef = useMemo(() => ({
        ...defaultColumnDef,
        editable: false,
        enableRowGroup: true,
        minWidth: 100,
        flex: 2,
        tooltipValueGetter: tooltipValueGetter,
    }), []);

    const { applyFilters, applyLayout, colDefs, layoutPanel, } = useDashboardLayout({
        gridRef: ref,
        layoutStorageKey,
        context: context,
        baseColDefs,
        defaultColDef,
    });

    const sideBar = useMemo(() => ({
        toolPanels: [
            columnPanel,
            filterPanel,
            layoutPanel,
        ]
    }), []);

    const onColorModeChanged = useCallback(({ mode, }) => {
        if (ref.current.api) {
            //redraw the rows when the theme changes
            ref.current.api.context.mode = mode;
            ref.current.api.redrawRows({ force: true });
        }
    }, [ref]);

    useColorMode(onColorModeChanged);

    const gridOptions = useMemo(() => {
        const discrepancyClass = (mode) => (params) => {
            const hasDiscrepancy = !!params.data?.discrepancy;
            const modeMatch = ref.current.api.context.mode === mode;
            return hasDiscrepancy && modeMatch;
        }

        return {
            rowClassRules: {
                "ag-row-discrepancy": discrepancyClass('light'),
                "ag-row-discrepancy-dark": discrepancyClass('dark'),
            },
        };
    }, [ref]);

    function onGridReady() {
        applyLayout();
        onColorModeChanged({ mode: theme.palette.mode });
    }

    const handleFirstDataRendered = useCallback(() => {
        applyFilters();
    }, [applyFilters]);

    const handleRowSelected = useCallback((params) => {
        const isSelected = params.node.selected;
        const selectedData = params.data;

        const fetchData = {
            ...getValues(),
            date: selectedData.date,
        };
        onSelectionChanged(fetchData, isSelected);
    }, [onSelectionChanged, getValues]);

    return (
        <AgGridContainer style={{ width: '100%', height: '100%' }} >
            <AgGridReact
                {...defaultGridOptions}
                containerStyle={{ height: '100%', width: '100%' }}
                getRowId={getRowId}
                gridOptions={gridOptions}
                rowData={rowData}
                ref={ref}
                columnDefs={colDefs}
                onFirstDataRendered={handleFirstDataRendered}
                rowSelection='multiple'
                rowMultiSelectWithClick
                onRowSelected={handleRowSelected}
                overlayNoRowsTemplate={'<span style="width: 70%; font-size: 16px">No data. Select a counterparty to view daily data.</span>'}
                onGridReady={onGridReady}
                columnTypes={columnTypes}
                sideBar={sideBar}
                tooltipShowDelay={350}
                tooltipHideDelay={60000}
                components={{
                    layoutToolPanel: LayoutToolPanel,
                }}
            />
        </AgGridContainer>
    );
});
