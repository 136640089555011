import { Box, useTheme } from '@mui/material';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-enterprise';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useEffect, useRef, useState } from 'react';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import { apiUrlPrefix, userGroups } from '../../authConfig';
import { DivGuard } from '../../components/Guards/DivGuardFlex.js';
import { useLocalGuid } from '../../data/UserGuidContext/useLocalGuid';
import '../../styles/userGridStyles.css';
import TemplateTable from '../TransNow/TemplateTable';
import useHeader from '../useHeader';
import { useTemplateTable } from '../useTemplateTable';
import UserGroupsGrid from './UserGroupsGrid';
import Users from './Users';

const UserMapping = () => {
    const theme = useTheme();
    const guid = useLocalGuid();
    const userGridRef = useRef();
    const userGroupGridRef = useRef();
    const [userTemplates, setUserTemplates] = useState([]);
    const { newTemplate, addDropZone, transformRows } = useTemplateTable(userTemplateRowTransform, undefined, userGridRef, userGroupGridRef, 'user-mapping', 'User Grid', 'Group Grid')
    const { enqueueSnackbar } = useSnackbar();

    const addIndex = 0;
    const header = useHeader();

    useEffect(() => {
        //loadTemplates();
        let abortController = new AbortController();
        // your async action is here  
        loadTemplates();
        return () => {
            abortController.abort();
        }
    }, []);

    useEffect(() => {
        if (userTemplates.length > 0 && userGridRef) {
            userTemplates.forEach(temp => {
                addDropZone(temp.id, (params) => transformRows(temp, params))
            });
        }
    }, [userTemplates, userGridRef]);

    async function loadTemplates() {
        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_GroupsFetch&parm=${guid}`
        let options = {
            headers: header,
            method: 'GET',
            url: url,
        };
        const userTemps = await axios(options).catch(err => { enqueueSnackbar(`Error loading groups.  URL: ${url} Error:  ${err}`) });

        if (userTemps) {
            setUserTemplates(userTemps.data.map(temp => newTemplate(temp.powerStationGroupID, temp, temp.groupName)));
        }
    };

    function addUserRowTemplateClick(temp) {
        const rows = userGridRef.current.api.getSelectedRows();

        if (!rows?.length > 0) {
            enqueueSnackbar('Please select users to map to a group.');
            return;
        }

        const users = rows.map(row => userTemplateRowTransform(row, temp.data));
        userGroupGridRef.current.api.applyTransaction({ add: users, addIndex: addIndex, });
    };

    function userTemplateRowTransform(row, template) {
        const newRow = { ...row }
        newRow['userGuid'] = row['Guid'];
        newRow['groupName'] = template.groupName;
        newRow['powerStationGroupID'] = template.powerStationGroupID;
        //newRow['AssignmentRef'] = undefined;

        return newRow;
    }

    return (
        <DivGuard groups={[userGroups.usermapping]}>
            <PanelGroup
                autoSaveId='UserMappingVerticalPanelGroup'
                direction='horizontal'
                style={{ overflow: 'hidden', height: '95vh' }}
            >

                <Panel
                    defaultSize={30}
                    id='user-mapping-left-panel'
                >
                    <Users ref={userGridRef} />
                </Panel>

                <PanelResizeHandle
                    style={{
                        width: theme.spacing(1),
                    }}
                />

                <Panel
                    defaultSize={70}
                    id='user-mapping-right-panel'
                    style={{ display: 'flex', overflow: 'hidden', }}
                >
                    <Box sx={{ width: '280px', height: '100%', overflowY: 'auto' }}>
                        <TemplateTable
                            tableId='user-mapping'
                            title='Groups'
                            tooltipText='These are user groups you can assign users to.'
                            templates={userTemplates}
                            handleRowClick={addUserRowTemplateClick}
                            rowTooltip={function (template) {
                                return (
                                    <span>{template.descriptionOfAccess}</span>
                                )
                            }} />
                    </Box>
                    <div className="user-container">
                        <UserGroupsGrid ref={userGroupGridRef} templates={userTemplates} />
                    </div>
                </Panel>
            </PanelGroup>
        </DivGuard>
    );
};

export default function UserGridsExport() {
    return <UserMapping />;
}
