import { userGroups } from "../../authConfig";
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Profile from "./Profile";
import OASIScredentials from "./OASIScredentials";
import GroupGuard from "../Guards/GroupGuard";
import FlexCollapse from "../FlexCollapse";
import WorkflowGrids from "./WorkflowGrids";
import TariffPrices from "../TransNow/TariffPrices";
import UserMapping from './UserMapping'
import CertificateMapping from './CertificateMapping'
import { TenantSettings } from './TenantSettings';
import SettingsAppMenu from './AppMenu';
import { ReleaseNotes } from "./ReleaseNotes";
import { AlertSettings } from "./AlertSettings";
import { LookupValuesManager } from "./LookupValuesManager";

function Settings() {
    const [selected, setSelected] = useState('profile');
    const [loadedViews, setLoadedViews] = useState({});

    useEffect(() => {
        if (selected && !loadedViews[selected]) {
            setLoadedViews({
                ...loadedViews,
                [selected]: true
            })
        }
    }, [selected]);

    return (
        <Box sx={{ height: '100%', display: 'flex', overflow: 'hidden', }}>
            <SettingsAppMenu selected={selected} setSelected={setSelected} />
            <Box sx={{ p: '7px', overflow: 'hidden', }} className='flex-column' >
                {loadedViews['usermapping'] && <FlexCollapse in={selected === 'usermapping'}>
                    <UserMapping />
                </FlexCollapse>}
                {loadedViews['profile'] && <FlexCollapse in={selected === 'profile'}>
                    <Profile />
                </FlexCollapse>}
                {/*<Collapse in={selected === 'usergroups'}>
            <UserGroups />  
          </Collapse>*/}
                <GroupGuard groups={[userGroups.realtimegrids, userGroups.dayaheadgrids, userGroups.bulkoriginalrequests, userGroups.dayaheadspreadsheet, userGroups.spymonkey]}>
                    {loadedViews['oasiscredentials'] && <Collapse in={selected === 'oasiscredentials'} >
                        <OASIScredentials />
                    </Collapse>}
                </GroupGuard>
                <GroupGuard groups={[userGroups.certificatemapping]}>
                    {loadedViews['certificatemapping'] && <FlexCollapse in={selected === 'certificatemapping'} >
                        <CertificateMapping />
                    </FlexCollapse>}
                </GroupGuard>
                <GroupGuard groups={[userGroups.admins]} >
                    {loadedViews['tenantsettings'] && <Collapse in={selected === 'tenantsettings'} >
                        <TenantSettings />
                    </Collapse>}
                </GroupGuard>
                <GroupGuard groups={[userGroups.workflow]} >
                    {loadedViews['workflowgrids'] && <Collapse in={selected === 'workflowgrids'} >
                        <WorkflowGrids />
                    </Collapse>}
                </GroupGuard>
                <GroupGuard groups={[userGroups.realtimegrids, userGroups.bulkoriginalrequests, userGroups.realtimeoriginals, userGroups.dayaheadgrids]} >
                    {loadedViews['tariffprices'] && <FlexCollapse in={selected === 'tariffprices'} >
                        <TariffPrices />
                    </FlexCollapse>}
                </GroupGuard>
                {loadedViews['releasenotes'] && <Collapse in={selected === 'releasenotes'} >
                    <ReleaseNotes />
                </Collapse>}
                {loadedViews['useralerts'] && <FlexCollapse in={selected === 'useralerts'} >
                    <AlertSettings />
                </FlexCollapse>}
                {loadedViews['lookupvalues'] && <FlexCollapse in={selected === 'lookupvalues'} >
                    <LookupValuesManager />
                </FlexCollapse>}
            </Box>
        </Box>
    );
}

export default Settings;
