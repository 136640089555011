import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import { Button, Stack, useTheme } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { useEffect, useMemo, useRef } from 'react';
import { userGroups } from "../../authConfig";
import { useLocalGuid } from '../../data/UserGuidContext/useLocalGuid';
import { AgGridContainer } from '../AgGrid/AgGridContainer';
import { defaultStatusBar } from '../AgGrid/defaultGridProps.js';
import { DivGuard } from '../Guards/DivGuardFlex.js';
import { columnPanel, filterPanel } from '../ToolPanels/DefaultToolPanels';
import { useApi } from '../useApi.js';
import { useData } from '../useData.js';
import { useGridButtons } from '../useGridButtons.js';

const TariffPrices = () => {
    const guid = useLocalGuid();
    const gridRef = useRef();
    const theme = useTheme();
    const { RedoButton, UndoButton, ExportButton, CopyRowsButton, RefreshButton, NewRowButton, SaveButton } = useGridButtons({ gridRef });
    const { rowData, getData, } = useData();
    const { post, apiUrlPrefix, enqueueSnackbar, } = useApi();

    const onRefreshBtn = () => {
        const loadUrl = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_TariffPricesFetch&parm=${guid}`;
        gridRef.current.api.showLoadingOverlay();
        getData(loadUrl);
        gridRef.current.api.hideOverlay();
    };

    const colDefs = useMemo(() => [
        {
            editable: false,
            headerName: "ID",
            headerCheckboxSelection: true,
            field: "tariffPriceID",
        },
        {
            editable: true,
            headerName: "Request Type",
            enableRowGroup: true,
            field: "RequestType",
        },
        {
            editable: true,
            headerName: "Provider",
            enableRowGroup: true,
            field: "Provider",
        },
        {
            editable: true,
            headerName: "Service Increment",
            enableRowGroup: true,
            field: "ServiceIncrement",
        },
        {
            editable: true,
            headerName: "TSClasee",
            enableRowGroup: true,
            field: "TSClass",
        },
        {
            editable: true,
            headerName: "TSType",
            enableRowGroup: true,
            field: "TSType",
        },
        {
            editable: true,
            headerName: "TSPeriod",
            enableRowGroup: true,
            field: "TSPeriod",
        },
        {
            editable: true,
            headerName: "TSWindow",
            enableRowGroup: true,
            field: "TSWindow",
        },
        {
            editable: true,
            headerName: "TSSubclass",
            enableRowGroup: true,
            field: "TSSubclass",
        },
        {
            editable: true,
            headerName: "Peak Cost",
            enableRowGroup: true,
            field: "peakPrice",
        },
        {
            editable: true,
            headerName: "Off Peak Cost",
            enableRowGroup: true,
            field: "offPeakPrice",
        },
        {
            editable: true,
            headerName: "OASIS Peak Price",
            enableRowGroup: true,
            field: "oasisPeakPrice",
        },
        {
            editable: true,
            headerName: "OASIS Off Peak Price",
            enableRowGroup: true,
            field: "oasisOffPeakPrice",
        },
    ], []);


    useEffect(() => {

        const loadUrl = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_TariffPricesFetch&parm=${guid}`;

        let abortController = new AbortController();
        // your async action is here  
        getData(loadUrl);
        return () => {
            abortController.abort();
        }

    }, []);

    function autoSizeAll(skipHeader) {
        const allColumnIds = [];
        gridRef.current.columnApi.getAllColumns().forEach((column) => {
            allColumnIds.push(column.getId());
        });

        gridRef.current.columnApi.autoSizeColumns(allColumnIds, skipHeader);
    }

    async function handleSaveButtonClick() {
        gridRef.current.api.stopEditing();

        const gridData = [];
        gridRef.current.api.forEachLeafNode((node) => {
            gridData.push(node.data);
        });

        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Push?name=PowerStationMetaData.UI_TariffPricesSave&parm=${guid}`;
        const message = 'Tariff Prices saved to database.';
        post(url, gridData).then((response) => {
            if (response.status === 200) {
                enqueueSnackbar(message, { variant: 'success' });
            }
        });
    }

    function onRowDataUpdated(e) {
        autoSizeAll(false);
    }

    const sideBar = {
        toolPanels: [
            columnPanel,
            filterPanel,
        ],
        position: 'right',
    };

    const defaultColDef = useMemo(() => ({
        editable: true,
        floatingFilter: true,
        filter: "agMultiColumnFilter",
        sortable: true,
        resizable: true,
    }), [])

    function addBlankRow() {
        const res = gridRef.current.api.applyTransaction({
            add: [{}],
            addIndex: 0,
        });
    };

    return (
        <DivGuard groups={[userGroups.realtimegrids, userGroups.bulkoriginalrequests, userGroups.realtimeoriginals, userGroups.dayaheadgrids, userGroups.spymonkey, userGroups.fastpathpro]} >
            <div className='flex-column'>
                <Stack direction="row" spacing={2} sx={{ py: 1 }}>
                    <Tooltip title="Refresh the list of tariff prices." arrow placement="top">
                        <RefreshButton onClick={onRefreshBtn} />
                    </Tooltip>
                    <Tooltip title="Undo the last edit made." arrow placement="top">
                        <UndoButton />
                    </Tooltip>
                    <Tooltip title="Redo the last edit made." arrow placement="top">
                        <RedoButton />
                    </Tooltip>
                    <Tooltip title="Copy the currently selected rows to the clipboard." arrow placement="top">
                        <CopyRowsButton />
                    </Tooltip>
                    <Tooltip title="Add a new blank row to the grid." arrow placement="top">
                        <Button
                            endIcon={<AddCircleOutlinedIcon />}
                            size='small'
                            variant='contained'
                            onClick={addBlankRow}
                        >Add New</Button>
                    </Tooltip>
                    <Tooltip title="Download the grid in CSV format to open in Excel." arrow placement="top">
                        <ExportButton />
                    </Tooltip>
                    <SaveButton onClick={handleSaveButtonClick} />
                </Stack>

                <AgGridContainer
                    style={{
                        height: "100%",
                        width: "100%",
                        paddingBottom: theme.spacing(1),
                    }}
                >
                    <AgGridReact
                        ref={gridRef}
                        rowData={rowData}
                        columnDefs={colDefs}
                        onRowDataUpdated={onRowDataUpdated}
                        storageLocation='powerstation-tariff-prices-grid-layout'
                        suppressRowClickSelection
                        enableFillHandle={true}
                        undoRedoCellEditing={true}
                        undoRedoCellEditingLimit={20}
                        enterMovesDownAfterEdit={true}
                        enableRangeSelection={true}
                        defaultColDef={defaultColDef}
                        sideBar={sideBar}
                        statusBar={defaultStatusBar}
                        animateRows={true}
                    />
                </AgGridContainer>
            </div>
        </DivGuard>
    );
};

export default function TariffPricesExport() {
    return <TariffPrices />;
}
