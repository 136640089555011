import { useTheme } from "@mui/material"
import { AgGridReact } from "ag-grid-react"
import { forwardRef, useCallback, useMemo, useRef } from "react"
import { useFormContext } from "react-hook-form"
import { useColorMode } from "../../../styles/ColorMode/useColorMode"
import { AgGridContainer } from "../../AgGrid/AgGridContainer"
import { defaultColumnDef, defaultGridOptions, defaultStatusBar } from "../../AgGrid/defaultGridProps"
import { useColumnTypes } from "../../AgGrid/useColumnTypes"
import dayjs from "../../dayjs-tz"
import { useBatchedHubAction } from "../../HubContext/useBatchedHubAction"
import { filterPanel, palettePanel } from "../../ToolPanels/DefaultToolPanels"
import { LayoutToolPanel } from "../../ToolPanels/LayoutToolPanel"
import { PaletteToolPanel } from "../../ToolPanels/PalettePanel"
import { useApi } from "../../useApi"
import { useUserInfo } from "../../UserInfoContext"
import { CurtailmentCellRenderer } from "../Review/CurtailmentCellRenderer"
import { useMultipleLayouts } from "../useMultipleLayouts"
import { useRowDebounce } from "../useRowDebounce"
import { heatmapStyle, isLongDay, jsonOrCommaSeparatedFormatter } from "../Utils"

export const HourlyGrid = forwardRef(({ context, getRowId, pivotHours, setSelectedRow, silentUpdate, toggleMWColumns, }, ref) => {
    const { columnTypes } = useColumnTypes();
    const paletteRef = useRef({ showHeatmap: false });
    const { watch, getValues } = useFormContext();
    const id = watch('id');
    const layoutStorageKey = `deal-rizz-checkout-hourly-grid-layout-${context.id}`;
    const { enqueueSnackbar, headers, post, apiUrlPrefix, } = useApi();
    const userInfo = useUserInfo();
    const theme = useTheme();

    const rowData = useMemo(() => {
        return [];
    }, []);

    const silentRefresh = useCallback(async (obj) => {
        const message = obj.hubMessage;
        if (!message) { //don't die if there is no message; this can happen if e.g. the previous fetch errored out
            enqueueSnackbar(`Changes detected. Updating...`, { variant: 'info' });
        } else {
            const changeType = obj.hubMessage.includes('deal') ? 'Deal' : 'Schedule';
            enqueueSnackbar(`${changeType} changes detected. Updating...`, { variant: 'info' });
        }
        return silentUpdate({
            ...getValues(),
            dealID: obj.dealID,
            scheduleID: obj.scheduleID,
        });
    }, [getValues, silentUpdate, enqueueSnackbar]);

    const gridHasMatch = useCallback((key, value) => {
        let hasMatch = false;
        ref.current.api?.forEachLeafNode(node => {
            if (String(node.data[key]) === String(value)) {
                hasMatch = true;
            }
        });
        return hasMatch;
    }, [ref]);

    const gridHasDealOrSchedule = useCallback((obj) => {
        if (obj.hubMessage === 'dealUpdate') {
            return gridHasMatch('dealID', obj.dealID);
        } else if (obj.hubMessage === 'scheduleUpdate') {
            return gridHasMatch('scheduleID', obj.scheduleID);
        }
    }, [gridHasMatch]);

    useBatchedHubAction({
        action: silentRefresh,
        allowedMessages: ['dealUpdate', 'scheduleUpdate', 'dealCreated', 'scheduleCreated'],
        createKey: (obj) => obj.dealID || obj.scheduleID,
        predicate: (obj) => {
            const isNew = obj.hubMessage === 'dealCreated' || obj.hubMessage === 'scheduleCreated';
            const isSameTenant = String(obj.tenantID) === String(userInfo?.tenantId)
            return isSameTenant && (isNew || gridHasDealOrSchedule(obj));
        },
        wait: 2000,
    });

    const scheduleIdCellClassRules = useMemo(() => {
        const noIdClass = (mode) => (params) => {
            const isGroupNode = params.node.group;
            const modeMatch = ref.current.api.context.mode === mode;
            return !params.value && modeMatch && !isGroupNode;
        }

        return {
            'ag-cell-no-schedule-id': noIdClass('light'),
            'ag-cell-no-schedule-id-dark': noIdClass('dark'),
        };
    }, [ref]);

    const hourlyCellClassRules = useMemo(() => {
        const discrepancyClass = (mode) => (params) => {
            const modeMatch = ref.current.api.context.mode === mode;
            const colId = params.column.colId;
            const hour = parseInt(colId);
            if (isNaN(hour) || !params.data?.[hour]) return false;
            const colData = params.data[hour];
            return !!colData.discrepancy && modeMatch;
        }

        return {
            'ag-cell-discrepancy': discrepancyClass('light'),
            'ag-cell-discrepancy-dark': discrepancyClass('dark'),
        };
    }, [ref]);

    const baseHourColDef = useMemo(() => ({
        floatingFilter: true,
        filter: 'agNumberColumnFilter',
        cellDataType: 'number',
        editable: true,
        width: 60,
        aggFunc: 'sum',
        enableRowGroup: false,
        resizable: true,
        cellClassRules: hourlyCellClassRules,
    }), [hourlyCellClassRules]);

    const rowTotal = (rowData, key) => {
        return Array.from({ length: 24 }, (_, i) => i + 1).reduce((acc, i) => {
            acc += rowData[i]?.[key] ?? 0;

            if (i === 2 && rowData['2*']) {
                acc += rowData['2*']?.[key] ?? 0;
            }

            return acc;
        }, 0);
    }

    const currencyFormatter = useCallback((formatOptions) => (params) => {
        const value = params.value;
        if (value !== null && value !== undefined) {
            // Format value as USD currency with commas
            return new Intl.NumberFormat('en-US', { ...formatOptions, }).format(value);
        }
        return value;
    }, []);

    const setSaleNegativePurchasePositive = (data, key, params) => {
        //if Transaction_Type is Sale, make sure that the value is negative
        //if Transaction_Type is Purchase, make sure that the value is positive
        const value = params.newValue;
        const Transaction_Type = params.data?.Transaction_Type;
        const saleAndPositive = Transaction_Type === 'Sale' && value > 0;
        const purchaseAndNegative = Transaction_Type === 'Purchase' && value < 0;
        if (saleAndPositive || purchaseAndNegative) {
            data[key] = -value;
        } else {
            data[key] = value;
        }
        return true;
    }

    const setSaleNegativePurchasePositiveFlat = useCallback((key) => {
        return (params) => {
            const data = params.data;
            if (!data) return false;

            return setSaleNegativePurchasePositive(data, key, params);
        }
    }, []);

    const setSaleNegativePurchasePositivePivot = useCallback((hour, key) => {
        return (params) => {
            let hourData = params.data[hour];
            if (!hourData) {
                hourData = {};
                params.data[hour] = hourData;
            }

            return setSaleNegativePurchasePositive(hourData, key, params);
        }
    }, []);

    const defaultHourColChildren = useCallback((i) => ([
        {
            ...baseHourColDef,
            headerName: 'Schedule MW',
            colId: `${i}.scheduleMW`,
            editable: (params) => {
                return !!params.data?.scheduleID;
            },
            cellStyle: params => heatmapStyle(params, paletteRef, 'showMWHeatmap'),
            valueFormatter: currencyFormatter(),
            valueGetter: (params) => {
                return params.data?.[i]?.scheduleMW;
            },
            valueSetter: setSaleNegativePurchasePositivePivot(i, 'scheduleMW'),
        },
        {
            ...baseHourColDef,
            headerName: 'Deal MW',
            colId: `${i}.dealMW`,
            cellStyle: params => heatmapStyle(params, paletteRef, 'showMWHeatmap'),
            valueFormatter: currencyFormatter(),
            valueGetter: (params) => {
                return params.data?.[i]?.dealMW;
            },
            valueSetter: setSaleNegativePurchasePositivePivot(i, 'dealMW'),
        },
        {
            ...baseHourColDef,
            headerName: 'Tag MW',
            colId: `${i}.tagMW`,
            field: `${i}.tagMW`,
            cellStyle: params => heatmapStyle(params, paletteRef, 'showMWHeatmap'),
            valueFormatter: currencyFormatter(),
            editable: false,
        },
        {
            ...baseHourColDef,
            field: `${i}.price`,
            colId: `${i}.price`,
            headerName: '$',
            cellStyle: params => heatmapStyle(params, paletteRef, 'showPriceHeatmap'),
            valueFormatter: currencyFormatter({ style: 'currency', currency: 'USD' }),
        },
    ]), [baseHourColDef, currencyFormatter, setSaleNegativePurchasePositivePivot]);

    const pivotHourColumns = useMemo(() => {
        return [
            {
                headerName: 'Schedule MWh',
                valueGetter: params => {
                    if (!!params.data) {
                        return rowTotal(params.data, 'scheduleMW');
                    }
                },
                valueFormatter: currencyFormatter(),
                aggFunc: 'sum',
                type: 'numericColumn',
                cellDataType: 'number',
                cellStyle: params => heatmapStyle(params, paletteRef),
                minWidth: 100,
                filter: 'agNumberColumnFilter',
                colId: 'totalSchedule_agg',
            },
            {
                headerName: 'Tag MWh',
                valueGetter: params => {
                    if (!!params.data) {
                        return rowTotal(params.data, 'tagMW');
                    }
                },
                valueFormatter: currencyFormatter(),
                aggFunc: 'sum',
                type: 'numericColumn',
                cellDataType: 'number',
                cellStyle: params => heatmapStyle(params, paletteRef),
                minWidth: 100,
                filter: 'agNumberColumnFilter',
                colId: 'totalSchedule_agg',
            },
            {
                headerName: 'Deal MWh',
                valueGetter: params => {
                    if (!!params.data) {
                        return rowTotal(params.data, 'dealMW');
                    }
                },
                valueFormatter: currencyFormatter(),
                aggFunc: 'sum',
                type: 'numericColumn',
                cellDataType: 'number',
                cellStyle: params => heatmapStyle(params, paletteRef),
                minWidth: 100,
                filter: 'agNumberColumnFilter',
                colId: 'totalDeal_agg',
            },
            ...Array.from({ length: 24 }, (_, i) => i + 1).reduce((acc, i) => {
                acc.push({
                    headerName: `${i}`,
                    field: `${i}`,
                    minWidth: 20,
                    type: 'numericColumn',
                    cellDataType: 'number',
                    initialWidth: 50,
                    children: defaultHourColChildren(i),
                });

                if (i === 2) {
                    acc.push({
                        headerName: '2*',
                        field: '2*',
                        colId: '2*',
                        minWidth: 20,
                        type: 'numericColumn',
                        cellDataType: 'number',
                        initialWidth: 50,
                        children: defaultHourColChildren('2*').map(colDef => ({
                            ...colDef,
                            //hide: true,
                        })),
                    });
                }

                return acc;

            }, []),
        ];
    }, [defaultHourColChildren, currencyFormatter]);

    const flatHourColumns = useMemo(() => {
        return [
            {
                field: 'HE',
                headerName: 'HE',
                enablePivot: true,
                pivot: true,
            },
            {
                ...baseHourColDef,
                headerName: 'Schedule MW',
                colId: `scheduleMW`,
                cellStyle: params => heatmapStyle(params, paletteRef, 'showMWHeatmap'),
                valueGetter: (params) => {
                    return params.data?.scheduleMW;
                },
                valueSetter: setSaleNegativePurchasePositiveFlat('scheduleMW'),
                editable: (params) => {
                    return !!params.data?.scheduleID;
                },
            },
            {
                ...baseHourColDef,
                headerName: 'Deal MW',
                colId: `dealMW`,
                field: `dealMW`,
                cellStyle: params => heatmapStyle(params, paletteRef, 'showMWHeatmap'),
                valueGetter: (params) => {
                    return params.data?.dealMW;
                },
                valueSetter: setSaleNegativePurchasePositiveFlat('dealMW'),
            },
            {
                ...baseHourColDef,
                headerName: 'Tag MW',
                colId: `tagMW`,
                field: `tagMW`,
                cellStyle: params => heatmapStyle(params, paletteRef, 'showMWHeatmap'),
                valueGetter: (params) => {
                    return params.data?.tagMW;
                },
                valueSetter: setSaleNegativePurchasePositiveFlat('tagMW'),
                editable: false,
            },
            {
                ...baseHourColDef,
                field: `price`,
                colId: `price`,
                headerName: 'Price',
                cellStyle: params => heatmapStyle(params, paletteRef, 'showPriceHeatmap'),
                valueFormatter: currencyFormatter({ style: 'currency', currency: 'USD' }),
            },
        ];
    }, [baseHourColDef, currencyFormatter, setSaleNegativePurchasePositiveFlat]);

    const hourColumns = useMemo(() => {
        if (pivotHours) {
            return pivotHourColumns;
        } else {
            return flatHourColumns;
        }
    }, [pivotHours, flatHourColumns, pivotHourColumns]);

    const baseColDefs = useMemo(() => ([
        {
            checkboxSelection: true,
            field: 'dealID',
            headerName: 'Deal ID',
        },
        {
            field: 'Deal Name',
        },
        {
            field: 'Transaction',
        },
        {
            field: 'Transaction Type',
        },
        {
            valueGetter: (params) => {
                return jsonOrCommaSeparatedFormatter(params.data?.Book);
            },
            headerName: 'Book',
            initialHide: true,
            colId: 'Book',
        },
        {
            valueGetter: (params) => {
                return jsonOrCommaSeparatedFormatter(params.data?.Strategy);
            },
            headerName: 'Strategy',
            initialHide: true,
            colId: 'Strategy',
        },
        {
            field: 'MarketPath',
            headerName: 'Market Path',
        },
        {
            headerName: 'Deal Notes',
            field: 'Deal Notes',
            initialHide: true,
        },
        {
            headerName: 'Schedule ID',
            field: 'scheduleID',
            filter: 'agNumberColumnFilter',
            cellDataType: 'number',
            cellClassRules: scheduleIdCellClassRules,
        },
        {
            field: 'Schedule_Status',
            headerName: 'Status',
            initialHide: true,
            filter: 'agSetColumnFilter',
            filterParams: {
                // provide all values, even if days are missing in data!
                values: [
                    'CONFIRMED',
                    'FLAGGED',
                ]
            },
        },
        {
            headerName: 'Schedule Comments',
            field: 'Schedule_Comment',
            initialHide: true,
            tooltipValueGetter: params => params.value,
        },
        {
            field: 'Tag Code',
            cellRenderer: 'curtailmentCellRenderer',
            cellStyle: params => {
                return {
                    paddingLeft: '0px',
                }
            },
        },
        {
            headerName: 'Tag Status',
            field: 'TagStatus',
        },
        {
            headerName: 'Deal Creator',
            field: 'dealCreator',
            initialHide: true,
        },
        {
            headerName: 'Deal Modifier',
            field: 'dealModifier',
            initialHide: true,
        },
        {
            headerName: 'Schedule Creator',
            field: 'scheduleCreator',
            initialHide: true,
        },
        {
            headerName: 'Schedule Modifier',
            field: 'scheduleModifier',
            initialHide: true,
        },
        {
            field: 'Flow Date',
            type: 'dateColumn',
            filter: 'agDateColumnFilter',
        },
        ...hourColumns,
        {
            field: 'discrepancy',
            headerName: 'Discrepancies',
            initialHide: true,
        },
        {
            field: 'anyDiscrepancyFlag',
            headerName: 'Discrepancy Flag',
            initialHide: true,
            aggFunc: 'sum',
        },
        {
            field: 'dealDiscrepancyFlag',
            headerName: 'Deal Discrepancy Flag',
            initialHide: true,
            aggFunc: 'sum',
        },
        {
            field: 'tagDiscrepancyFlag',
            headerName: 'Tag Discrepancy Flag',
            initialHide: true,
            aggFunc: 'sum',
        },
    ]), [hourColumns]);

    const tooltipValueGetter = (params) => {
        const discrepancy = params.data?.discrepancy;
        if (discrepancy) {
            return `Discrepancy: ${discrepancy}`;
        } else {
            return '';
        }
    };

    const defaultColDef = useMemo(() => ({
        ...defaultColumnDef,
        editable: false,
        enableRowGroup: true,
        minWidth: 100,
        flex: 2,
        tooltipValueGetter: tooltipValueGetter,
    }), []);

    const gridContainerId = `deal-rizz-checkout-hourly-grid-${id}-container`;

    const allColDefs = useMemo(() => [baseColDefs, flatHourColumns, pivotHourColumns], [baseColDefs, flatHourColumns, pivotHourColumns]);
    const { resetLayout, loadLayout, saveLayoutLocal, deleteLayoutLocal, colDefs, loadFilters, } = useMultipleLayouts(layoutStorageKey, ref, baseColDefs, allColDefs, defaultColDef, context?.layout);

    const layoutPanel = useMemo(() => ({
        id: 'layout',
        labelDefault: 'Layout',
        labelKey: 'layout',
        iconKey: 'menu',
        toolPanel: 'layoutToolPanel',
        toolPanelParams: {
            onLayoutClear: () => {
                resetLayout();
                enqueueSnackbar('Layout reset.', { variant: 'info' });
            },
            onLayoutLoad: loadLayout,
            onLayoutSave: () => {
                saveLayoutLocal();
                enqueueSnackbar('Layout saved.', { variant: 'info' });
            },
            onLayoutDelete: () => {
                deleteLayoutLocal();
                enqueueSnackbar('Layout deleted.', { variant: 'info' });
            },
        },
        minWidth: 180,
        maxWidth: 400,
        width: 200
    }), [resetLayout, loadLayout, saveLayoutLocal, deleteLayoutLocal, enqueueSnackbar]);

    const columnPanel = useMemo(() => ({
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
            suppressPivotMode: true,
        },
        minWidth: 225,
        maxWidth: 225,
        width: 225
    }), []);

    const sideBar = useMemo(() => ({
        toolPanels: [
            columnPanel,
            filterPanel,
            layoutPanel,
            palettePanel(ref, paletteRef, 'deal-rizz-checkout-hourly-palette', gridContainerId),
        ]
    }), [ref, paletteRef, layoutPanel, gridContainerId]);

    const onColorModeChanged = useCallback(({ mode, }) => {
        if (ref.current.api) {
            //redraw the rows when the theme changes
            ref.current.api.context.mode = mode;
            ref.current.api.redrawRows({ force: true });
        }
    }, [ref]);

    useColorMode(onColorModeChanged);

    const gridOptions = useMemo(() => {
        const discrepancyClass = (mode) => (params) => {
            const hasDiscrepancy = !!params.data?.discrepancy;
            const modeMatch = ref.current.api.context.mode === mode;
            return hasDiscrepancy && modeMatch;
        }

        return {
            rowClassRules: {
                "ag-row-discrepancy": discrepancyClass('light'),
                "ag-row-discrepancy-dark": discrepancyClass('dark'),
            },
        };
    }, [ref]);

    const toggleExtraHourColBasedOnDates = useCallback((startDate, endDate) => {
        const startStr = dayjs(startDate).format('YYYY-MM-DD'); //cast to string to avoid offset issues
        const endStr = dayjs(endDate).format('YYYY-MM-DD');
        let start = dayjs(startStr).startOf('day');
        const end = dayjs(endStr).startOf('day');
        let longDayInRange = false;

        do {
            const isLong = isLongDay(start);
            if (isLong) {
                longDayInRange = true;
                break;
            }
            start = start.add(1, 'day');
        } while (start.isSameOrBefore(end, 'day'));

        const colApi = ref.current?.columnApi;
        colApi?.setColumnVisible('2*.scheduleMW', longDayInRange);
        colApi?.setColumnVisible('2*.price', longDayInRange);
        colApi?.setColumnVisible('2*.dealMW', longDayInRange);
        colApi?.setColumnVisible('2*.tagMW', longDayInRange);
    }, [ref]);

    function onGridReady() {
        loadLayout();
        onColorModeChanged({ mode: theme.palette.mode });
        const startDate = getValues('startDate');
        const stopDate = getValues('stopDate');
        toggleExtraHourColBasedOnDates(startDate, stopDate);
    }

    const handleFirstDataRendered = useCallback(() => {
        loadFilters();
    }, [loadFilters]);

    const handleRowDataUpdated = useCallback(() => {
        const startDate = getValues('startDate');
        const stopDate = getValues('stopDate');
        toggleExtraHourColBasedOnDates(startDate, stopDate);
    }, [getValues, toggleExtraHourColBasedOnDates]);

    const handleSelectionChanged = useCallback((event) => {
        const selectedNodes = event.api.getSelectedNodes();
        const selectedNode = selectedNodes?.[0];
        const selectedData = selectedNode?.data;
        setSelectedRow(selectedData);
    }, [setSelectedRow]);

    const hours = useMemo(() => {
        return Array.from({ length: 24 }, (_, i) => i + 1);
    }, []);

    const handleModifyDeal = useCallback(async (updatedRow) => {
        const { timezone, } = getValues();

        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Push?name=dealrizz.UI_ModifyDealPosition`
            + `&parm=${headers.userGuid}`
            + `&parm=${updatedRow.dealID}`
            + `&parm=${updatedRow['Flow Date']}`
            + `&parm=${timezone}`

        let profile = {};

        if (pivotHours) {
            profile = hours.reduce((acc, i) => {
                acc[i] = {
                    MW: updatedRow[i]?.dealMW,
                    Price: updatedRow[i]?.price,
                };
                return acc;
            }, {});
        } else {
            profile = {
                [updatedRow.HE]: {
                    MW: updatedRow.dealMW,
                    Price: updatedRow.price,
                }
            }
        }

        return post(url, JSON.stringify(profile)).then(response => {
            if (response?.status === 200) {
                enqueueSnackbar("Deal Profile updated successfully", { variant: "success" });
            }
        });
    }, [enqueueSnackbar, post, headers, getValues, apiUrlPrefix, hours, pivotHours]);

    const handleModifySchedule = useCallback(async (updatedRow) => {
        const { timezone, } = getValues();
        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Push?name=dealrizz.UI_ModifyScheduleProfile`
            + `&parm=${headers.userGuid}`
            + `&parm=${updatedRow.scheduleID}`
            + `&parm=${updatedRow.dealID}`
            + `&parm=${updatedRow['Flow Date']}`
            + `&parm=${timezone}`;

        let profile = {};
        if (pivotHours) {
            profile = hours.reduce((acc, i) => {
                acc[i] = updatedRow[i]?.scheduleMW;
                return acc;
            }, {});
        } else {
            profile = {
                [updatedRow.HE]: updatedRow.scheduleMW,
            }
        }
        return post(url, JSON.stringify(profile)).then(response => {
            if (response?.status === 200) {
                enqueueSnackbar("Schedule Profile updated successfully", { variant: "success" });
            }
        });
    }, [enqueueSnackbar, post, headers, getValues, apiUrlPrefix, hours, pivotHours]);

    const { batchRowUpdate: batchDealUpdate, } = useRowDebounce({ onTimeExpired: handleModifyDeal });
    const { batchRowUpdate: batchScheduleUpdate, } = useRowDebounce({ onTimeExpired: handleModifySchedule });

    function handleCellValueChanged(event) {
        if (event.column.colId.includes('dealMW') || event.column.colId.includes('price')) {
            batchDealUpdate(event);
        } else if (event.column.colId.includes('scheduleMW')) {
            batchScheduleUpdate(event);
        }
    }

    const handleNewColumnsLoaded = useCallback(() => {
        const { startDate, stopDate, showScheduleMW, showDealMW, showTagMW, showPrice, } = getValues();
        toggleMWColumns('scheduleMW', showScheduleMW, startDate, stopDate);
        toggleMWColumns('dealMW', showDealMW, startDate, stopDate);
        toggleMWColumns('tagMW', showTagMW, startDate, stopDate);
        toggleMWColumns('price', showPrice, startDate, stopDate);
    }, [getValues, toggleMWColumns]);

    return (
        <AgGridContainer style={{ width: '100%', height: '100%' }} id={gridContainerId}>
            <AgGridReact
                {...defaultGridOptions}
                rowData={rowData}
                containerStyle={{ height: '100%', width: '100%' }}
                getRowId={getRowId}
                ref={ref}
                rowSelection="single"
                rowMultiSelectWithClick
                onCellValueChanged={handleCellValueChanged}
                onSelectionChanged={handleSelectionChanged}
                onNewColumnsLoaded={handleNewColumnsLoaded}
                columnDefs={colDefs}
                gridOptions={gridOptions}
                onFirstDataRendered={handleFirstDataRendered}
                onRowDataUpdated={handleRowDataUpdated}
                overlayNoRowsTemplate={'<span style="width: 70%; font-size: 16px">No data. Select a daily deal to view hourly data.</span>'}
                onGridReady={onGridReady}
                columnTypes={columnTypes}
                sideBar={sideBar}
                statusBar={defaultStatusBar}
                tooltipShowDelay={350}
                tooltipHideDelay={60000}
                suppressAggFuncInHeader={true}
                components={{
                    layoutToolPanel: LayoutToolPanel,
                    paletteToolPanel: PaletteToolPanel,
                    curtailmentCellRenderer: CurtailmentCellRenderer,
                }}
            />
        </AgGridContainer>
    );
});
