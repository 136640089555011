import { AgGridReact } from "ag-grid-react"
import { forwardRef, useCallback, useMemo } from "react"
import { AgGridContainer } from "../../AgGrid/AgGridContainer"
import { defaultColumnDef, defaultGridOptions } from "../../AgGrid/defaultGridProps"
import { useColumnTypes } from "../../AgGrid/useColumnTypes"
import { columnPanel, filterPanel } from "../../ToolPanels/DefaultToolPanels"
import { LayoutToolPanel } from "../../ToolPanels/LayoutToolPanel"
import { useDashboardLayout } from "../useDashboardLayout"
import { useFormContext } from "react-hook-form";
import { jsonOrCommaSeparatedFormatter } from "../Utils";

export const CounterpartyGrid = forwardRef(({ context, getRowId, onSelectionChanged, }, ref) => {
    const { columnTypes } = useColumnTypes();
    const layoutStorageKey = `deal-rizz-checkout-summary-grid-layout-${context.id}`;
    const { getValues } = useFormContext();

    const baseColDefs = useMemo(() => ([
        {
            field: 'Counterparty',
            checkboxSelection: true,
        },
        // {
        //     field: 'Transaction',
        // },
        {
            field: 'MW Purchased',
            type: 'numericColumn',
            cellDataType: 'number',
            filter: 'agNumberColumnFilter',
            valueFormatter: (params) => {
                const value = params.value;
                if (value !== null && value !== undefined) {
                    // Format value as USD currency with commas
                    return new Intl.NumberFormat('en-US').format(value);
                }
                return value;
            },
        },
        {
            field: 'MW Sold',
            type: 'numericColumn',
            cellDataType: 'number',
            filter: 'agNumberColumnFilter',
            valueFormatter: (params) => {
                const value = params.value;
                if (value !== null && value !== undefined) {
                    // Format value as USD currency with commas
                    return new Intl.NumberFormat('en-US').format(value);
                }
                return value;
            },
        },
        {
            field: 'Net MW',
            type: 'numericColumn',
            cellDataType: 'number',
            filter: 'agNumberColumnFilter',
            valueFormatter: (params) => {
                const value = params.value;
                if (value !== null && value !== undefined) {
                    // Format value as USD currency with commas
                    return new Intl.NumberFormat('en-US').format(value);
                }
                return value;
            },
        },
        {
            field: 'Energy Purchased',
            type: 'numericColumn',
            cellDataType: 'number',
            filter: 'agNumberColumnFilter',
            valueFormatter: (params) => {
                const value = params.value;
                if (value !== null && value !== undefined) {
                    // Format value as USD currency with commas
                    return new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD'
                    }).format(value);
                }
                return value;
            },
        },
        {
            field: 'Energy Sold',
            type: 'numericColumn',
            cellDataType: 'number',
            filter: 'agNumberColumnFilter',
            valueFormatter: (params) => {
                const value = params.value;
                if (value !== null && value !== undefined) {
                    // Format value as USD currency with commas
                    return new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD'
                    }).format(value);
                }
                return value;
            },
        },
        {
            field: 'Net Energy',
            type: 'numericColumn',
            cellDataType: 'number',
            filter: 'agNumberColumnFilter',
            valueFormatter: (params) => {
                const value = params.value;
                if (value !== null && value !== undefined) {
                    // Format value as USD currency with commas
                    return new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD'
                    }).format(value);
                }
                return value;
            },
        },
    ]), []);

    const defaultColDef = useMemo(() => ({
        ...defaultColumnDef,
        editable: false,
        enableRowGroup: true,
        minWidth: 100,
        flex: 2,
    }), []);

    const { applyFilters, applyLayout, colDefs, layoutPanel, } = useDashboardLayout({
        gridRef: ref,
        layoutStorageKey,
        context: context,
        baseColDefs,
        defaultColDef,
    });

    const sideBar = useMemo(() => ({
        toolPanels: [
            columnPanel,
            filterPanel,
            layoutPanel,
        ]
    }), []);

    function onGridReady() {
        applyLayout();
    }

    const handleFirstDataRendered = useCallback(() => {
        applyFilters();
    }, [applyFilters]);

    const handleSelectionChanged = useCallback((event) => {
        const selectedNodes = event.api.getSelectedNodes();
        const selectedData = selectedNodes.map(node => node.data);
        const fetchData = {
            ...getValues(),
            counterparty: selectedData[0]?.Counterparty,
        };
        onSelectionChanged(fetchData);
    }, [onSelectionChanged, getValues]);

    return (
        <AgGridContainer style={{ width: '100%', height: '100%' }} >
            <AgGridReact
                {...defaultGridOptions}
                containerStyle={{ height: '100%', width: '100%' }}
                getRowId={getRowId}
                ref={ref}
                columnDefs={colDefs}
                onFirstDataRendered={handleFirstDataRendered}
                onGridReady={onGridReady}
                columnTypes={columnTypes}
                sideBar={sideBar}
                rowSelection='single'
                rowMultiSelectWithClick
                onSelectionChanged={handleSelectionChanged}
                components={{
                    layoutToolPanel: LayoutToolPanel,
                }}
            />
        </AgGridContainer>
    );
});
