import { Stack } from "@mui/material";
import axios from 'axios';
import dayjs from "dayjs";
import { forwardRef, useCallback, useImperativeHandle, useMemo, useRef, useState } from "react";
import * as yup from 'yup';
import { useApi } from "../../useApi";
import { useLayoutFunctions } from "../../useLayoutFunctions";
import { rangeHasLongDate, diffData } from "../Utils";
import { ViewContextProvider } from "../ViewContextProvider";
import { CollaborationGrid } from "./CollaborationGrid";
import { CollaborationRibbon } from "./Ribbon/CollaborationRibbon";

const schema = yup.object().shape({
    timezone: yup.string().required('Timezone is required'),
    startDate: yup.date().required('Start Date is required'),
    stopDate: yup.date().required('Stop Date is required'),
    counterparty: yup.string().required('Counterparty is required'),
    transaction: yup.string().required('Transaction is required'),
    book: yup.string().required('Book is required'),
});

export const CollaborationView = forwardRef(({ view, }, ref) => {
    const gridRef = useRef();
    const toolbarFormId = `collaboration-toolbar-form-${view.id}`;
    const { captureLayout } = useLayoutFunctions();
    const [filterGroups, setFilterGroups] = useState(!!view.filterGroups);
    const [pivotHours, setPivotHours] = useState(!!view.pivotHours);
    const [selectedData, setSelectedData] = useState();
    const { headers, apiUrlPrefix, handleErrorResponse } = useApi();

    const defaults = useMemo(() => ({
        timezone: 'Pacific Standard Time',
        book: 'All',
        includeFlagged: true,
        ...view,
        startDate: dayjs().startOf('month'),
        stopDate: dayjs(),
    }), [view]);

    useImperativeHandle(ref, () => {
        return {
            captureLayout: () => captureLayout(gridRef),
        };
    });

    const getRowId = useCallback((params) => {
        const { data, } = params;
        return `${data.dealID}-${data.scheduleID}-${data['Tag Code']}-${data['Flow Date']}-${data.HE}`;
    }, []);

    const hours = useMemo(() => {
        return Array.from({ length: 24 }, (_, i) => i + 1).reduce((acc, i) => {
            acc.push(`${i}`);
            return acc;
        }, []);
    }, []);

    const isNullOrUndefined = (value) => value === null || value === undefined;

    const flattenData = useCallback((data) => {
        return data.reduce((acc, item) => {
            hours.forEach((hour) => {
                const hourData = item[hour] ?? {};
                const flattenedRowData = {
                    ...item,
                    ...hourData,
                    HE: hour,
                    scheduleMW: hourData.scheduleMW,
                    price: hourData.price,
                    dealMW: hourData.dealMW,
                    Energy: hourData.Energy,
                    tagMW: hourData.tagMW,
                };
                if (!['dealMW', 'scheduleMW', 'price', 'Energy', 'tagMW'].every(key => isNullOrUndefined(hourData[key]))) {
                    acc.push(flattenedRowData);
                }
            });
            return acc;
        }, []);
    }, [hours]);

    const rollUpData = useCallback((data) => {
        const formatted = data.reduce((acc, item) => {
            const key = String(item.dealID) + item.scheduleID + item['Tag Code'] + item['Flow Date'];
            const existing = acc.get(key) ?? {};
            const updated = {
                ...existing,
                ...item,
                [`${item.HE}`]: {
                    scheduleMW: item.scheduleMW,
                    price: item.price,
                    dealMW: item.dealMW,
                    Energy: item.Energy,
                    tagMW: item.tagMW,
                    discrepancy: item.discrepancy,
                },
            };
            acc.set(key, updated);
            return acc;
        }, new Map());

        return Array.from(formatted.values());
    }, []);

    const requestKey = data => `${data.date}-${data.dealID}-${data.scheduleID}`;
    const pendingRequestsRef = useRef({});
    const fetchData = useCallback(async (data) => {
        const rKey = requestKey(data);
        if (pendingRequestsRef.current[rKey]) {
            pendingRequestsRef.current[rKey].cancel();
        }

        // Create a new CancelToken
        const source = axios.CancelToken.source();

        // Store the cancel function so we can cancel this request later
        pendingRequestsRef.current[rKey] = source;

        const { counterparty, startDate, stopDate, timezone, transaction, book, dealID, scheduleID, } = data;

        const criteria = JSON.stringify({
            dealID,
            scheduleID,
        });

        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/postFetchArray?name=dealrizz.UI_fetchHourlyEnergyData_v4`
            + `&parm=${headers.userGuid}`
            + `&parm=${counterparty}`
            + `&parm=${transaction}`
            + `&parm=${book}`
            + `&parm=${dayjs(startDate).format('MM/DD/YYYY')}`
            + `&parm=${dayjs(stopDate).format('MM/DD/YYYY')}`
            + `&parm=${timezone}`

        return axios.post(url, criteria, { headers, cancelToken: source.token }).catch(err => handleErrorResponse(err, url));
    }, [headers, apiUrlPrefix, handleErrorResponse]);

    const toggleExtraHourColBasedOnDate = useCallback((startDate, endDate) => {
        const colApi = gridRef.current?.columnApi;
        const isLong = rangeHasLongDate(startDate, endDate);
        colApi?.setColumnVisible('2*.scheduleMW', isLong);
        colApi?.setColumnVisible('2*.price', isLong);
        colApi?.setColumnVisible('2*.dealMW', isLong);
        colApi?.setColumnVisible('2*.tagMW', isLong);
        colApi?.setColumnVisible('2*.Energy', isLong);
    }, []);

    const applyData = useCallback((response, deleteData = true,) => {
        if (response?.data && gridRef.current) {
            let newData = response?.data ?? [];

            const oldData = [];
            gridRef.current?.api.forEachLeafNode(node => {
                oldData.push(node.data);
            });

            const { toAdd, toUpdate, toDelete, } = diffData(newData, oldData, (data) => getRowId({ data, }));
            const dataToDelete = deleteData ? toDelete : [];
            gridRef.current.api.applyTransaction({ add: toAdd, update: toUpdate, remove: dataToDelete });
        }
        return response;
    }, [getRowId]);

    const formatAndApplyData = useCallback((data, startDate, stopDate, deleteData = true) => {
        const formatted = data.map(item => ({
            ...item,
            'Flow Date': dayjs(item['Flow Date']).format('MM/DD/YYYY'),
        }));

        if (pivotHours) {
            const grouped = rollUpData(formatted);
            applyData({ data: grouped }, deleteData);
        } else {
            applyData({ data: formatted }, deleteData);
        }
        toggleExtraHourColBasedOnDate(startDate, stopDate);
    }, [applyData, pivotHours, rollUpData, toggleExtraHourColBasedOnDate]);

    const silentUpdate = useCallback(async (formData) => {
        return fetchData(formData).then(response => {
            if (response) {
                const responseData = response.data ?? [];
                formatAndApplyData(responseData, formData.startDate, formData.stopDate, false);
            }
        });
    }, [fetchData, formatAndApplyData]);

    const handleFetchData = useCallback(async (formData) => {
        gridRef.current?.api?.showLoadingOverlay();
        gridRef.current?.api?.deselectAll();
        fetchData(formData).then(response => {
            if (response) {
                const responseData = response.data ?? [];
                formatAndApplyData(responseData, formData.startDate, formData.stopDate);
            }
        });
    }, [fetchData, formatAndApplyData]);

    const pivotData = useCallback((pivot) => {
        const data = [];
        gridRef.current?.api.forEachLeafNode((node) => {
            data.push(node.data);
        });

        gridRef.current?.api.setRowData([]);
        if (pivot) {
            const grouped = rollUpData(data);
            applyData({ data: grouped });
        } else {
            const flattened = flattenData(data);
            applyData({ data: flattened });
        }

    }, [applyData, rollUpData, flattenData]);

    function handleUpdatePivot() {
        setPivotHours(p => {
            pivotData(!p);
            return !p;
        });
    }

    const toggleMWColumns = useCallback((colKey, show, startDate, stopDate) => {
        const longDayInRange = rangeHasLongDate(startDate, stopDate);

        const hours = Array.from({ length: 26 }, (_, i) => i + 1).reduce((acc, i) => {
            acc.push(`${i}`);

            if (i === 2 && longDayInRange) {
                acc.push('2*');
            }

            return acc;
        }, []);

        hours.forEach((hour) => { // show/hide all MW columns for each hour; handles pivot mode
            gridRef.current?.columnApi?.setColumnVisible(`${hour}.${colKey}`, show);
        });

        gridRef.current?.columnApi?.setColumnVisible(`${colKey}`, show); //this handles the non-pivot mode
    }, [gridRef]);

    const handleHourlySelectionChanged = useCallback((data) => {
        setSelectedData(data);
    }, []);

    const getBookoutData = useCallback(() => {
        if (!selectedData) {
            return null;
        }

        const dealId = selectedData.dealID;
        const flowDate = selectedData['Flow Date'];

        let rowData = [];
        gridRef.current?.api.forEachLeafNode(node => {
            const nodeData = node.data;
            if (nodeData['Flow Date'] === flowDate && nodeData.dealID === dealId) {
                rowData.push(nodeData);
            }
        });

        if (!pivotHours) {
            //make sure the row data is pivoted for consistency
            rowData = rollUpData(rowData);
        }

        const dealData = hours.reduce((acc, hour) => {
            const rowWithNonNullValue = rowData.find(row => !isNullOrUndefined(row[hour]?.dealMW))
            const dealMW = rowWithNonNullValue ? rowWithNonNullValue[hour]?.dealMW : 0;
            return {
                ...acc,
                [hour]: dealMW,
            };
        }, { id: dealId, dealName: selectedData['Deal Name'], });

        const dealPriceData = hours.reduce((acc, hour) => {
            const rowWithNonNullValue = rowData.find(row => !isNullOrUndefined(row[hour]?.price))
            const dealPrice = rowWithNonNullValue ? rowWithNonNullValue[hour]?.price : 0;
            return {
                ...acc,
                [hour]: dealPrice,
            };
        }, { id: 'Deal Price', });

        const dataWithSchedules = rowData.filter(row => row.scheduleID);

        const scheduleData = dataWithSchedules.map(row => {
            return hours.reduce((acc, hour) => {
                const scheduleMW = row[hour]?.scheduleMW ?? 0;
                return {
                    ...acc,
                    [hour]: scheduleMW,
                };
            }, { id: row.scheduleID, });
        });

        return {
            date: selectedData['Flow Date'],
            deal: dealData,
            price: dealPriceData,
            schedules: scheduleData,
        };
    }, [pivotHours, rollUpData, hours, selectedData]);

    const handleFlagStatusChanged = (scheduleId, toFlag, comments) => {
        //iterate over all grid data and update the Schedule_Status and Schedule_Comments fields for rows with the matching scheduleId
        const toUpdate = [];
        gridRef.current?.api.forEachLeafNode(node => {
            const data = node.data;
            if (data.scheduleID === scheduleId) {
                const newComment = toFlag ? 'FLAGGED: ' + comments : data.Schedule_Comment + ' CONFIRMED: ' + comments;
                const updatedData = {
                    ...data,
                    Schedule_Status: toFlag ? 'FLAGGED' : 'CONFIRMED',
                    Schedule_Comment: newComment,
                };
                toUpdate.push(updatedData);
            }
        });
        gridRef.current?.api.applyTransaction({ update: toUpdate });
    }

    const fieldsToIgnore = ['showScheduleMW', 'showDealMW', 'showTagMW', 'showPrice'];

    const handleScheduleVoided = useCallback(() => {
        const id = selectedData.scheduleID;
        const scheduleWasVoided = selectedData.Schedule_Status !== 'VOIDED';

        //iterate over all grid rows and update the schedule status if the schedule id matches
        const toUpdate = [];
        gridRef.current.api.forEachLeafNode(node => {
            const nodeScheduleId = node.data.scheduleID;
            if (nodeScheduleId === id) {
                toUpdate.push({
                    ...node.data,
                    Schedule_Status: scheduleWasVoided ? 'VOIDED' : 'DRAFT',
                });
            }
        });

        gridRef.current.api.applyTransaction({ update: toUpdate, });
    }, [selectedData]);

    return (
        <ViewContextProvider schema={schema} defaults={defaults} onSubmit={handleFetchData} fieldsToIgnore={fieldsToIgnore}>
            <Stack className='flex-column' spacing={1}>
                <CollaborationRibbon
                    gridRef={gridRef}
                    toolbarFormId={toolbarFormId}
                    handleRefresh={handleFetchData}
                    filterGroups={filterGroups}
                    setFilterGroups={setFilterGroups}
                    pivotHours={pivotHours}
                    setPivotHours={handleUpdatePivot}
                    toggleMWColumns={toggleMWColumns}
                    getBookoutData={getBookoutData}
                    selectedData={selectedData}
                    onFlagStatusChanged={handleFlagStatusChanged}
                    handleScheduleVoided={handleScheduleVoided}
                />

                <CollaborationGrid
                    ref={gridRef}
                    getRowId={getRowId}
                    filterGroups={filterGroups}
                    pivotHours={pivotHours}
                    toggleExtraHourCol={toggleExtraHourColBasedOnDate}
                    toggleMWColumns={toggleMWColumns}
                    setSelectedData={handleHourlySelectionChanged}
                    silentUpdate={silentUpdate}
                />
            </Stack>
        </ViewContextProvider >
    )
});
