import { useMemo } from "react";
import dayjs from '../dayjs-tz';

export const useColumnTypes = (params = {}) => {
    const { dateFormat = 'MM/DD/YYYY HH:mm:ss', } = params;

    const dateComparator = (a, b) => {
        const aDate = dayjs(a);
        const bDate = dayjs(b);
        if (aDate.isBefore(bDate)) {
            return -1;
        } else if (aDate.isAfter(bDate)) {
            return 1;
        } else {
            return 0;
        }
    }

    const columnTypes = useMemo(() => {
        return {
            dateColumn: {
                filter: 'agDateColumnFilter',
                comparator: dateComparator,
                filterParams: {
                    comparator: function (filterValue, cellValue) {
                        if (dayjs(cellValue).isBefore(filterValue, 'day')) {
                            return -1;
                        } else if (dayjs(cellValue).isAfter(filterValue, 'day')) {
                            return 1;
                        } else {
                            return 0;
                        }
                    },
                },
                valueFormatter: params => {
                    return params.value ? dayjs(params.value).format(params.colDef.dateFormat ?? dateFormat) : '';
                }
            }
        }
    }, [dateFormat]);

    return {
        columnTypes,
    }
}
