import { useEffect, createContext, useRef, useState, useContext, useCallback, } from 'react';
import "react-tabs/style/react-tabs.css"
import ConfirmationDialog from '../../TSRActivity/ConfirmationDialog';
import { useUserLayouts } from "../../useUserLayouts";
import { useActionAudit } from "../../useActionAudit";
import { useParams } from 'react-router';
import { useNavigate } from 'react-router';
import { useSnackbar } from 'notistack';
import debounce from 'lodash/debounce';

const DashboardContext = createContext();

export const useDashboard = () => {
    return useContext(DashboardContext);
}

export const DashboardProvider = (props) => {
    const { children, visible, dashboardKey, useShared, AddNewOrUpdateDialog, } = props;
    const { layouts: views, setLayouts: setViews, deleteLayout: handleDelete, insertUpdateLayout: handleSave, loading, } = useUserLayouts(dashboardKey, useShared);
    const params = useParams();
    const viewId = params.id;
    const { logAction } = useActionAudit();
    const navigate = useNavigate();
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
    const [openAddNew, setOpenAddNew] = useState(false);
    const currentView = views.find(v => v.id.toString() === viewId) ?? {};
    const viewsRef = useRef([]);
    const { enqueueSnackbar } = useSnackbar();
    const lastViewIdKey = `${dashboardKey}-last-view-id`;
    const [viewToUpdate, setViewToUpdate] = useState({});

    //if there is no viewId in the URL, set id to the id of the first view
    useEffect(() => {
        if (!viewId && views.length && visible) {
            //try to get the last view id from local storage
            const lastViewId = localStorage.getItem(lastViewIdKey);
            const navigateViewId = lastViewId && views.find(v => v.id.toString() === lastViewId) ? lastViewId : views[0].id;

            navigate(`./${navigateViewId}`, { relative: 'path' });
        }
    }, [viewId, views, visible, lastViewIdKey, navigate]);

    function handleConfirmDelete(view) {
        setOpenConfirmDelete(false);
        localStorage.removeItem(`deal-rizz-${dashboardKey}-grid-layout-${view.id}`);
        handleDelete(view.id).then(response => {
            const remaining = views?.filter(v => v.id !== view.id);
            if (remaining?.length === 0) { //if they deleted the last view, show Add New dialog
                setOpenAddNew(true);
            }
            navigate(`../${remaining[0]?.id ?? ''}`, { relative: 'path' });
            logAction(`User deleted the ${dashboardKey} view ${view.label}`, dashboardKey, JSON.stringify(view));
        });
    }

    const handleUpdate = (updatedView) => {
        const oldView = views.find(v => v.id === currentView.id);

        setViews(views.map(view => view.id === updatedView.id ? updatedView : view));

        //also save the layout
        const idx = views.findIndex(v => v.id === updatedView.id);
        const layout = viewsRef.current[idx]?.captureLayout();
        const viewToSave = { ...updatedView, layout };
        handleSave(viewToSave, false).then(response => {
            if (response?.status === 200) {
                enqueueSnackbar('View updated successfully.', { variant: 'success' });
                logAction(`User updated the ${dashboardKey} view ${oldView.label}`, dashboardKey, `Old view data: ${JSON.stringify(oldView)} New view data: ${JSON.stringify(viewToSave)}`);
            }
        });
    };

    const handleUpdateDebounced = useCallback(
        debounce(handleUpdate, 1000),
        [views, setViews, handleSave, enqueueSnackbar, currentView]
    );

    const handleAddNew = (newView) => {
        logAction(`User created a new ${dashboardKey} view ${newView.label}`, dashboardKey, JSON.stringify(newView));
        handleSave(newView).then(response => {
            const newId = response?.data;
            if (viewId === 'add-new') {
                navigate(`../${newId}`, { relative: 'path' });
            } else {
                navigate(`./${newId}`, { relative: 'path' });
            }
        });
    }

    const handleAddNewDebounced = useCallback(
        debounce(handleAddNew, 1000),
        [views, setViews, handleSave, enqueueSnackbar, currentView]
    );

    function handleAddNewOrUpdate(view = currentView) {
        setOpenAddNew(false);
        const isExistingView = view.id;
        if (isExistingView) { //update it
            handleUpdateDebounced(view);
        } else { //create a new one
            handleAddNewDebounced(view)
        }
    };

    function handleCancelAddNew() {
        setOpenAddNew(false);
        if (!currentView.id) {
            navigate(-1);
        }
    }

    function handleCopyCurrentView() {
        const newView = { ...currentView, label: '', id: null };
        setViewToUpdate(newView);
        setOpenAddNew(true);
    }

    function handleUpdateCurrentView() {
        setViewToUpdate(currentView);
        setOpenAddNew(true);
    }

    function handleAddNewView() {
        setViewToUpdate({});
        setOpenAddNew(true);
    }

    function handleDeleteCurrentView() {
        setOpenConfirmDelete(true);
    }

    return (
        <DashboardContext.Provider value={{ viewsRef, visible, handleConfirmDelete, handleAddNewOrUpdate, views, loading, dashboardKey, setOpenConfirmDelete, handleCopyCurrentView, handleUpdateCurrentView, handleAddNewView, handleDeleteCurrentView, }}>
            <AddNewOrUpdateDialog
                open={openAddNew || (viewId === 'add-new' && visible)}
                viewToUpdate={viewToUpdate}
                handleCancel={handleCancelAddNew}
                handleAddNew={handleAddNewOrUpdate}
                allViewLabels={views.map(v => v.label)}
            />
            <ConfirmationDialog
                open={openConfirmDelete}
                message={`You are about to delete the current tab${currentView?.label ? ' ' + currentView.label : ''}. Continue?`}
                onCancel={() => setOpenConfirmDelete(false)}
                onConfirmation={() => handleConfirmDelete(currentView)}
            />
            {children}
        </DashboardContext.Provider>
    );
}

