import { Checkbox, FormControlLabel, Stack } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { RibbonGroupContainer } from "../../Ribbon";
import { RefreshButton } from "../../RibbonButtons";
import { VisibleColumnPicker } from "../../Review/VisibleColumnPicker";
import { IncludeFlaggedCheckbox } from "../../IncludeFlaggedCheckbox";

export const GridActionsGroup = ({ gridRef, formId, expanded, handleRefresh, filterGroups, setFilterGroups, pivotHours, setPivotHours, toggleMWColumns, }) => {
    const { getValues, watch, setValue } = useFormContext();
    const { showScheduleMW, showDealMW, showTagMW } = getValues();
    const defaultVisible = { scheduleMW: showScheduleMW, dealMW: showDealMW, tagMW: showTagMW };
    const startDate = watch('startDate');
    const stopDate = watch('stopDate');
    const defaultIncludeFlagged = !!watch('includeFlagged');
    const columnLabels = {
        scheduleMW: 'Schedule MW',
        dealMW: 'Deal MW',
        tagMW: 'Tag MW',
    };

    const handleToggleColumn = (key, visible) => {
        toggleMWColumns(key, visible, startDate, stopDate);
        const formKey = key === 'scheduleMW' ? 'showScheduleMW' : key === 'dealMW' ? 'showDealMW' : 'showTagMW';
        setValue(formKey, visible);
    }

    return (
        <RibbonGroupContainer sx={{ overflow: 'visible', position: 'relative', }}>

            <RefreshButton
                form={formId}
                expanded={expanded}
                onRefresh={handleRefresh}
            />

            <Stack>
                <IncludeFlaggedCheckbox
                    gridRef={gridRef}
                    defaultChecked={defaultIncludeFlagged}
                    checkboxProps={{ sx: { py: 0, } }}
                />

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={filterGroups}
                            onClick={() => setFilterGroups(f => !f)}
                            sx={{ py: 0, }}
                        />
                    }
                    label="Filter Groups"
                />

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={pivotHours}
                            onClick={() => setPivotHours(p => !p)}
                            sx={{ py: 0, }}
                        />
                    }
                    label="Pivot Hours"
                />
            </Stack>

            <Stack>
                <VisibleColumnPicker
                    toggleColumn={handleToggleColumn}
                    colKeys={['scheduleMW', 'dealMW', 'tagMW']}
                    colLabels={columnLabels}
                    defaultChecked={defaultVisible}
                />
            </Stack>

        </RibbonGroupContainer>
    )
}
