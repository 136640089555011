import { Tab, Tabs, Box, Tooltip, Divider, List, ListItem, } from '@mui/material';
import { useParams, useNavigate } from 'react-router';
import { useDashboard } from './useDashboard';
import AddIcon from '@mui/icons-material/Add';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import { ViewSelector } from './ViewSelector';
import { useState, useMemo, useLayoutEffect } from 'react';

export const DashboardTabs = () => {
    const { views, dashboardKey, handleDeleteCurrentView, handleAddNewView, handleUpdateCurrentView, } = useDashboard();
    const params = useParams();
    const viewId = params.id;
    const navigate = useNavigate();
    const storageKey = `${dashboardKey}-views`;
    const [hiddenViews, setHiddenViews] = useState([]);
    const [sortOrder, setSortOrder] = useState(views.map(view => view.id));

    useLayoutEffect(() => {
        const savedState = JSON.parse(localStorage.getItem(storageKey));
        if (savedState) {
            setHiddenViews(savedState.hidden ?? []);
            setSortOrder(savedState.custom ?? []);
        }
    }, [storageKey]);

    function handleCustomOrderChanged(newOrder) {
        setSortOrder(newOrder);
    }

    const visibleViews = useMemo(() => views.filter(view => !hiddenViews.includes(view.id)), [views, hiddenViews]);

    //use the sort order to sort the visible views
    //if the view is not in the sort order, it will be sorted to the end
    const sortedViews = useMemo(() => {
        const sorted = visibleViews.sort((a, b) => {
            const aIdx = sortOrder.indexOf(a.id);
            const bIdx = sortOrder.indexOf(b.id);
            if (aIdx === -1) {
                return 1;
            }
            if (bIdx === -1) {
                return -1;
            }
            return aIdx - bIdx;
        });
        return sorted;
    }, [visibleViews, sortOrder]);

    const tabIndex = Math.max(sortedViews.findIndex(view => view.id.toString() === viewId), 0);

    function handleTabSelect(_, newIndex) {
        if (newIndex < sortedViews.length + 1) { //don't switch to the edit or delete tabs
            const id = sortedViews[newIndex]?.id ?? 'add-new';
            navigate(`../${id}`, { relative: 'path' });
            localStorage.setItem(`${dashboardKey}-last-view-id`, id);
        }
    }

    function handleViewToggled(id, visible) {
        setHiddenViews((prev) => {
            if (visible) {
                return prev.filter((vId) => vId !== id);
            } else {
                //if the view is the current view, switch to the first view that is not hidden
                if (String(viewId) === String(id)) {
                    const firstVisible = views.findIndex((view) => {
                        const notHidden = !prev.includes(view.id);
                        const notCurrent = view.id !== id;
                        return notHidden && notCurrent;
                    });
                    if (firstVisible >= 0) {
                        handleTabSelect(null, firstVisible);
                    }
                }
                return [...prev, id];
            }
        });
    }

    return (
        <Box sx={{
            display: 'flex',
        }}>
            <ViewSelector
                storageKey={storageKey}
                onViewToggled={handleViewToggled}
                onCustomOrderChanged={handleCustomOrderChanged}
            />
            <Tabs
                value={tabIndex}
                onChange={handleTabSelect}
                indicatorColor="primary"
                textColor="primary"
                color="primary"
                sx={{ maxHeight: '40px', minHeight: '40px' }}
                variant='scrollable'
                scrollButtons
                allowScrollButtonsMobile
            >
                {sortedViews.map((view) => (
                    <Tooltip title={<Box sx={{ pt: 2, }}>
                        <Divider>View Info</Divider>
                        <List dense sx={{ fontSize: '.75rem', }}>
                            <ListItem>Created by: {view.userName}</ListItem>
                            <ListItem>Is Shared: {!!view.shared ? 'True' : 'False'}</ListItem>
                        </List>
                        <Divider />
                    </Box>} key={`${dashboardKey}-dashboard-tab-${view.id}`} placement='bottom' arrow>
                        <Tab
                            sx={{ maxHeight: '40px', minHeight: '40px' }}
                            label={view.label}
                        //key={`${dashboardKey}-dashboard-tab-${view.id}`}
                        />
                    </Tooltip>
                ))}
                <Tab
                    key='addNewTab'
                    label='Add New'
                    icon={<AddIcon />}
                    iconPosition='start'
                    onClick={handleAddNewView}
                    sx={{
                        maxHeight: '40px',
                        minHeight: '40px'
                    }}
                />
                <Tab
                    key='editCurrentTab'
                    label='Edit Current'
                    icon={<EditIcon />}
                    iconPosition='start'
                    onClick={handleUpdateCurrentView}
                    disabled={!views[tabIndex]}
                    sx={{
                        maxHeight: '40px',
                        minHeight: '40px'
                    }}
                />
                <Tab
                    key='deleteCurrentTab'
                    label='Delete Current'
                    icon={<RemoveCircleOutlineIcon />}
                    iconPosition='start'
                    onClick={handleDeleteCurrentView}
                    disabled={!views[tabIndex]}
                    sx={{
                        maxHeight: '40px',
                        minHeight: '40px'
                    }}
                />
            </Tabs>
        </Box>
    );
}


